import { Component } from "react";
import LogBookSearch from "./LogBookSearch";
import LayoutWrapper from "../LayoutWrapper";
import * as XLSX from "xlsx";
import { Link } from "react-router-dom";

class LogBook extends Component {
  constructor(props) {
    super(props);
    props.initialconstruct.bind(this)("LogBook");
    this.state = {
      rowcount: 0,
      logbookData: [],
      search: {
        limit: 10,
        order_by: "updatedAt",
        order_direction: "DESC",
        page: 1,
      },
    };
    this.columns = [
      {
        dataIndex: "id",
        display: false,
      },
      {
        title: "Employee ID",
        dataIndex: "employee_id",
      },
      {
        title: "Employee Name",
        dataIndex: "employee_name",
        render: (text, record) => {
          return (
            <Link
              to={`/admin/logbook/employeeloglist?employee_id=${
                record?.employee_id
              }&employee_name=${text.replace(/\s+/g, "_").toLowerCase()}
              &month_year=${record?.date.slice(3)}`}
              className="link_btn"
            >
              {text}
            </Link>
          );
        },
      },
      {
        title: "Role",
        dataIndex: "employee_role",
      },
      {
        title: "Month",
        dataIndex: "date",
        render: (text, record) => {
          return <>{this.getMonthStr(text)}</>;
        },
      },
      {
        title: "Total Km.",
        dataIndex: "totalKilometer",
        render: (text) => {
          return <>{text?.toLocaleString("en-IN")}</>;
        },
      },
      {
        title: "Total Amt.",
        dataIndex: "totalAmount",
        render: (text, record) => text?.cur("", "INR", "en-US"),
      },
    ];
  }

  get isAdmin() {
    return this.props.store.user?.employee_role === "admin";
  }

  fetchList() {
    if (this.isAdmin) {
      this.props.api
        .apiGetAllAdminLogs(this.state.search)
        .then(({ data, total }) => {
          this.setState({
            logbookData: data,
            rowcount: total,
          });
        });
    } else {
      this.props.api
        .apiGetAllLogs(this.state.search)
        .then(({ data, total }) => {
          this.setState({
            logbookData: data,
            rowcount: total,
          });
        });
    }
  }

  componentDidMount() {
    this.fetchList();
  }

  onSearch(reset, data) {
    // Remove properties with empty values
    const filteredObj = Object.fromEntries(
      Object.entries(data).filter(([key, value]) => value)
    );

    let payload;
    if (reset) {
      payload = {
        ...filteredObj,
      };
    } else {
      payload = {
        ...this.state.search,
        ...data,
      };
    }
    this.setState({ search: payload }, () => {
      this.fetchList();
    });
  }

  onPageChange(pageData) {
    this.setState(
      {
        search: {
          ...this.state.search,
          page: pageData.current,
          limit: pageData.pageSize || pageData.defaultPageSize || 25,
        },
      },
      () => this.fetchList()
    );
  }

  getMonthStr(date) {
    let [day, month, year] = date.split("/");
    const dateObj = new Date(+year, +month - 1, +day).toISOString();
    let result = new Date(dateObj).getMonthDisplay();
    return result || "";
  }

  getDownloadData() {
    let { rowcount, search } = this.state;
    let pageCount = Math.ceil(rowcount / search.limit);
    // console.log(pageCount);
    let promises = [];
    promises.length = pageCount;
    promises.fill(0);
    // console.log(promises);
    promises = promises.map((v, i) =>
      this.api.apiGetAllAdminLogs({
        ...search,
        page: i + 1,
      })
    );
    // console.log(promises);
    Promise.all(promises).then((results) => {
      // console.log({ results });
      let totalrows = results.reduce((col, item) => col.concat(item.data), []);
      totalrows = totalrows.map(async (item) => {
        let res = () => "";
        let promise = new Promise((r) => {
          res = r;
        });
        this.getLogsByMonth(item?.employee_id, item?.date?.slice(3))
          .then((apidata) => {
            // console.log({ apidata });
            res({
              ...item,
              date: this.getMonthStr(item.date),
              monthData: apidata,
            });
          })
          .catch((err) => {
            res({
              ...item,
              date: this.getMonthStr(item.date),
              monthData: [],
            });
          });
        return promise;
      });
      Promise.all(totalrows).then((data) => {
        // console.log({ data });
        this.handleDownload(data);
      });
      // let { data } = this.processData(totalrows);
      // console.log({ totalrows });

      this.handleDownload(totalrows);
    });
  }

  getLogsByMonth(id, month) {
    let payload = {
      ...this.state.search,
      employee_id: id,
      month_year: month,
    };
    let res, rej;
    let promise = new Promise((r, rej) => {
      res = r;
    });
    this.props.api.apiGetLogsByMonth(payload).then(({ data }) => {
      let filteredData = data?.map((obj) => {
        let retobj = {
          date: obj.date,
          total_kilometer: obj.total_kilometer,
          amount: obj.amount,
          vehicle_type: obj.vehicle_type,
          from_location: obj.from_location,
          to_location: obj.to_location,
          vehicle_number: obj.vehicle_number,
          start_meter: obj.start_meter,
          end_meter: obj.end_meter,
        };

        retobj["start-latitude/longitude"] =
          obj.start_latitute == undefined || obj.start_longitute === undefined
            ? "-"
            : `${obj.start_latitute} / ${obj.start_longitute}`;

        retobj["end-latitude/longitude"] =
          obj.end_latitute == undefined || obj.end_longitute === undefined
            ? "-"
            : `${obj.end_latitute} / ${obj.end_longitute}`;

        return retobj;
      });
      res(filteredData);
    });
    return promise;
  }

  handleDownload = (totaldata = []) => {
    console.log({ handleDownload: totaldata });
    const workbook = XLSX.utils.book_new(); // Creating xcel file or work book;
    // console.log("data", data, { tabledata, addeddata, groups, groupsarr });
    // console.log("columns", columns);
    let excelHeaders = Object.keys(totaldata[0] || {});
    excelHeaders = excelHeaders.filter((v) => v != "monthData");
    // let excelData = totaldata.map((row) => excelHeaders.map((key) => row[key]));
    // const logSummaryData = [excelHeaders, ...excelData];
    // const logSummaryDataWorksheet = XLSX.utils.aoa_to_sheet(logSummaryData); // preparing logSummaryData to Log Book Summary Sheet
    // XLSX.utils.book_append_sheet(
    //   workbook,
    //   logSummaryDataWorksheet,
    //   "Log Book Summary"
    // );
    // attaching logSummaryData to Log Book Summary Sheet
    // Create a new workbook and worksheet
    let orderedHeaders = [
      "employee_id",
      "employee_name",
      "employee_role",
      "email_address",
      "date",
      "totalAmount",
      "totalKilometer",
      "mobile_num",
      "createdAt",
      "updatedAt",
      "start-latitude/longitude",
      "end-latitude/longitude",
    ];
    excelHeaders = [
      ...orderedHeaders,
      ...excelHeaders.filter((k) => !orderedHeaders.includes(k)),
    ];
    console.log({ excelHeaders });

    // let logDetailexcelData = totaldata.reduce((collection, row) => {
    //   let rowData = excelHeaders.map((key) => row[key]);
    //   let monthData = row["monthData"];
    //   // console.log({ monthData }, row["monthData"], row);
    //   let monthDataHeader = ["", ...Object.keys(monthData[0] || {})];
    //   let monthDataValues = monthData.map((mdrow) =>
    //     monthDataHeader.map((key) => (key ? mdrow[key] : ""))
    //   );
    //   collection.push(rowData);
    //   collection.push(monthDataHeader);
    //   monthDataValues.map((mdrow) => collection.push(mdrow));
    //   return collection;
    // }, []);
    // let logDetailData = [excelHeaders, ...logDetailexcelData];
    // let logDetailDataWorksheet = XLSX.utils.aoa_to_sheet(logDetailData); // preparing logDetailexcelData to Log Book Details Sheet
    // XLSX.utils.book_append_sheet(
    //   workbook,
    //   logDetailDataWorksheet,
    //   "Log Book Details"
    // );
    let logDetailexcelData = totaldata.reduce((collection, row, i) => {
      let monthData = row["monthData"];
      // console.log({ monthData }, row["monthData"], row);
      let monthDataHeader = [
        "employee_id",
        "employee_name",
        "employee_role",
        "month",
        ...Object.keys(monthData[0] || {}),
        // "start_latitute",
        // "start_longitute",
        // "end_latitute",
        // "end_longitute",
      ];

      // CONVERT RESPONSE DATA
      // console.log({ monthData });
      // let modifiedData = monthData.map((obj) => {
      //   // Combine "start_latitute" and "start_longitute" as "start-latitude/longitude"
      //   // console.log({ obj });
      //   obj["start-latitude/longitude"] =
      //     obj.start_latitute == undefined || obj.start_longitute === undefined
      //       ? "-"
      //       : `${obj.start_latitute} / ${obj.start_longitute}`;

      //   obj["end-latitude/longitude"] =
      //     obj.end_latitute == undefined || obj.end_longitute === undefined
      //       ? "-"
      //       : `${obj.end_latitute} / ${obj.end_longitute}`;

      //   // Remove the individual properties
      //   delete obj.start_latitute;
      //   delete obj.start_longitute;
      //   delete obj.end_latitute;
      //   delete obj.end_longitute;

      //   return obj;
      // });

      // console.log({ modifiedData });

      let monthDataValues = monthData.map((mdrow) => {
        mdrow.employee_id = row["employee_id"];
        mdrow.employee_name = row["employee_name"];
        mdrow.employee_role = row["employee_role"];
        mdrow.month = row["date"];
        return monthDataHeader.map((key) => (key ? mdrow[key] : ""));
      });
      // monthDataHeader = monthDataHeader.map((key) =>
      //   key.replace(/\s+/g, " ").toTitleCase()
      // );
      // console.log({ monthDataHeader, monthDataValues });
      if (i == 0) {
        collection.push(monthDataHeader);
      }
      monthDataValues.map((mdrow) => collection.push(mdrow));
      return collection;
    }, []);
    let logDetailData = [...logDetailexcelData];
    let logDetailDataWorksheet = XLSX.utils.aoa_to_sheet(logDetailData); // preparing logDetailexcelData to Log Book Details Sheet
    XLSX.utils.book_append_sheet(
      workbook,
      logDetailDataWorksheet,
      "Log Book Details"
    );

    // Generate a unique filename for the Excel sheet
    const filename = `log_book_${new Date().getTime()}.xlsx`;

    // Export the workbook as an Excel file

    XLSX.writeFile(workbook, filename);
  };

  render() {
    let { logbookData } = this.state;
    let { columns } = this;
    return (
      <LayoutWrapper title="Log Book" back="Log Book">
        <div className="row">
          <div className="col"></div>
          <div className="">
            <button
              type="button"
              className="btn add-btn"
              onClick={() => this.getDownloadData()}
            >
              <i class="fa fa-download me-2"></i>
              Download
            </button>
          </div>
        </div>
        <LogBookSearch
          inactive={this.props.inactive}
          onSearch={(...arg) => this.onSearch(...arg)}
        />
        <div className="row mb-4">
          <div className="col">
            <AppTable
              data={logbookData}
              columns={columns}
              reorder={true}
              onChange={(...arg) => this.onPageChange(...arg)}
              total={this.state.rowcount}
              editable={false}
              deletable={false}
              viewable={false}
              pageSize={10}
            ></AppTable>
          </div>
        </div>
      </LayoutWrapper>
    );
  }
}

export default connect(LogBook);
