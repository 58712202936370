import React, { Component } from "react";
import { Link } from "react-router-dom";
import * as XLSX from "xlsx";
import LayoutWrapper from "../LayoutWrapper";
import FamilyMemberModel from "./familyMemberModel";
export class DataCollectionResponse extends Component {
  constructor(props) {
    super(props);
    props.initialconstruct.bind(this)("DataCollectionResponse");
    this.state = {
      data: [],
      familyList: [],
      modalTitle: "Members",
      rowcount: 0,
      search: {
        limit: 10,
        orderBy: "updatedOn",
        orderDirection: "DESC",
        page: 1,
        // projectName: "Digital Literacy",
      },
      columns: [],
      downloadLink: "",
    };
    this.memberModalRef = React.createRef();
  }

  get isAdmin() {
    return this.props.store.user?.employee_role === "admin";
  }

  get isManager() {
    return this.props.store.user?.employee_role === "manager";
  }

  componentDidMount() {
    this.fetchList();
  }

  handleDownload = (totaldata = []) => {
    const { data: pagedata, columns } = this.state;
    let data = totaldata.length ? totaldata : pagedata;
    const tabledata = data.reduce((arr, row) => {
      let mrow = Object.except(row, [
        "categoryInfoArr",
        "answers",
        "lastUpdatedBy",
      ]);
      if (row?.categoryInfoArr.length) {
        row?.categoryInfoArr.map((member) => {
          let subrow = member.categoryInfo.map((collection) => {
            return collection.reduce(
              (c, o) => ({ ...c, [o.categoryLabel]: o.categoryResponse }),
              {}
            );
          });
          let isArray = mrow[member.fieldLabel] instanceof Array;
          mrow[member.fieldLabel] = isArray ? mrow[member.fieldLabel] : [];
          mrow[member.fieldLabel] = subrow;
        });
      }
      arr.push(mrow);
      return arr;
    }, []);
    let groups = {};
    let addeddata = tabledata.reduce((arr, trow, index) => {
      let row = data[index];
      let isRowHasArray = Object.values(trow).reduce(
        (flag, value) => flag || value instanceof Array,
        false
      );
      let arrRemovedRow = Object.filter(trow, (v) => !(v instanceof Array));
      arrRemovedRow = Object.filter(
        arrRemovedRow,
        (v, k) => !["projectId", "formId"].includes(k)
      );
      arr.push(arrRemovedRow);
      if (isRowHasArray) {
        let formId = row._id;
        let formName = row.formName;
        let projectId = row.projectId;
        let arrayValues = Object.filter(trow, (v) => v instanceof Array);
        Object.entries(arrayValues).map(([key, values], j) => {
          groups[formId + "-" + j] = groups[formId + "-" + j] || [];
          values.map((value) => {
            groups[formId + "-" + j].push({
              id: formId,
              formName,
              subcategory: key,
              ...value,
            });
          });
        });
      }
      return arr;
    }, []);
    let groupsarr = Object.entries(groups).reduce((arr, [key, values]) => {
      arr = arr.concat(values);
      return arr;
    }, []);
    console.log({ addeddata });
    addeddata = addeddata.map((item) => {
      return {
        ...item,
        createdOn: new Date(item?.createdOn).dateToDDMMYYYY(
          new Date(item?.createdOn)
        ),
        updatedOn: new Date(item?.updatedOn).dateToDDMMYYYY(
          new Date(item?.updatedOn)
        ),
      };
    });
    addeddata = addeddata.map(({ updatedOn, ...rest }) => rest);
    console.log("data", data, { tabledata, addeddata, groups, groupsarr });
    console.log("columns", columns);
    // Prepare data for Excel export
    const handleCellData = (record, column) => {
      if (column.dynamicColumn) {
        if (column.dynamicColumn.categoryInfo.length) {
          return this.getFamilyMember(
            record,
            record[column.dataIndex],
            column.dynamicColumn.key
          );
        } else {
          return record[column.dataIndex];
        }
      } else {
        return record[column.dataIndex];
      }
    };
    // const exportData = [
    // 	columns.map((column) => column.title), // Header row
    // 	...data.map((record) => columns.map((column) => handleCellData(record, column))), // Data rows
    // ];
    let orderedHeaders = ["_id", "Village", "formName"];
    let mainHeaders = Object.keys(addeddata[0] || {});
    mainHeaders = [
      ...orderedHeaders,
      ...mainHeaders.filter((k) => !orderedHeaders.includes(k)),
    ];
    let mainData = addeddata.map((v) => mainHeaders.map((k) => v[k]));
    let groupHeaders = Object.keys(groupsarr[0] || {});
    let groupData = groupsarr.map((v) => groupHeaders.map((k) => v[k]));
    let concatedHeader = [...mainHeaders, ...groupHeaders.slice(2)];
    let concatedData = mainData.reduce((c, r) => {
      c.push(r);
      let grouprows = groupData.filter((o) => o[0] == r[0]);
      if (grouprows.length) {
        grouprows.map((gr) => {
          c.push([...r, ...gr.slice(2)]);
        });
      }
      return c;
    }, []);
    console.log({ concatedHeader });
    // CHANGE HEADER NAME
    concatedHeader = concatedHeader.map((item) => {
      if (item === "createdBy") {
        item = "DS ID";
      } else if (item === "createdByName") {
        item = "DS Name";
      } else if (item === "formName") {
        item = "Form Name";
      } else if (item === "createdOn") {
        item = "Created On";
      } else if (item === "updatedOn") {
        item = "Updated On";
      }
      return item;
    });

    const exportData = [concatedHeader, ...concatedData];
    console.log("mainheaders", {
      mainHeaders,
      addeddata,
      tabledata,
      exportData,
    });
    //return;

    // REMOVE "_id" and its values from export data
    let alteredData = exportData.map((subArray, i) => {
      if (subArray[0] === "_id") {
        return subArray.slice(1);
      } else if (/^[a-fA-F0-9]{24}$/.test(subArray[0])) {
        return subArray.slice(1);
      } else {
        return subArray;
      }
    });
    console.log({ alteredData });

    // Create a new workbook and worksheet
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.aoa_to_sheet(alteredData);

    // Add the worksheet to the workbook
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

    // Generate a unique filename for the Excel sheet
    const filename = `data_collection_response_${new Date().getTime()}.xlsx`;

    // Export the workbook as an Excel file

    XLSX.writeFile(workbook, filename);
  };

  getDownloadData(payload = {}) {
    let { total, search } = this.state;
    let limit = 10000;
    let pageCount = Math.ceil(total / limit);
    let promises = [];
    promises.length = pageCount;
    promises.fill(0);
    console.log(promises);
    promises = promises.map((v, i) =>
      this.api.apiGetDataCollectionFormResponse(
        {
          ...search,
          ...(payload.startDate
            ? {
                startDate: payload.startDate,
              }
            : {}),
          ...(payload.endDate
            ? {
                endDate: payload.endDate,
              }
            : {}),
          page: i + 1,
          limit,
        },
        {
          id: this.props.urlparams.id,
        }
      )
    );
    console.log(promises);
    Promise.all(promises).then((results) => {
      console.log({ PROMISES: results });
      let totalrows = results.reduce((col, item) => col.concat(item.data), []);
      let { data } = this.processData(totalrows);
      console.log({ totalrows, data });
      this.handleDownload(data);
    });
    //this.handleDownload();
  }

  getDownloadDates() {
    let self = this;
    prompt(
      `Enter the download range`,
      {},
      <div id="prompt" className="row text-left">
        <div className="col-sm-12">
          <AppInput label="Start Date" name="startDate" type="date"></AppInput>
        </div>
        <div className="col-sm-12">
          <AppInput label="End Date" name="endDate" type="date"></AppInput>
        </div>
      </div>
    ).promise.then(() => {
      let data = document.querySelector("#appmodal").getData();
      self.getDownloadData(data);
    });
  }

  getFamilyMember(record, text, key) {
    let selectedRecord = record.categoryInfoArr.filter((obj) => {
      return obj.fieldResponse === text && key === obj.fieldLabel;
    });
    let memeber = selectedRecord[0].categoryInfo;
    let modalTitle = selectedRecord[0].fieldLabel;
    let familyList = memeber.map((arr, i) => ({
      group: arr.reduce(
        (c, v) => ({
          ...c,
          [v.categoryLabel.snakeCase()]: v.categoryResponse,
        }),
        {}
      ),
      groupfields: arr.reduce(
        (c, v) => ({
          ...c,
          [v.categoryLabel.snakeCase()]: {
            type: "text",
            name: v.categoryLabel.snakeCase(),
            label: v.categoryLabel,
            disabled: true,
          },
        }),
        {}
      ),
      subtitle: `#${i + 1}`,
    }));
    return familyList
      .map((o) => o.group)
      .reduce((c, obj, i) => {
        let keys = Object.keys(obj);
        return (
          c +
          `Member ${i + 1} \n` +
          keys.map((key) => `${key} : ${obj[key]}`).join("\n") +
          "\n\n"
        );
      }, "");
  }

  setFamilyMember(record, text, key) {
    console.log({ record, text, key });

    let selectedRecord = record.categoryInfoArr.filter((obj) => {
      return obj.fieldResponse === text && key === obj.fieldLabel;
    });

    console.log({ selectedRecord: selectedRecord });

    let memeber = selectedRecord[0].categoryInfo;
    let modalTitle = selectedRecord[0].fieldLabel;
    let familyList = memeber.map((arr, i) => ({
      group: arr.reduce(
        (c, v) => ({
          ...c,
          [v.categoryLabel.snakeCase()]: v.categoryResponse,
        }),
        {}
      ),
      groupfields: arr.reduce(
        (c, v) => ({
          ...c,
          [v.categoryLabel.snakeCase()]: {
            type: "text",
            name: v.categoryLabel.snakeCase(),
            label: v.categoryLabel,
            disabled: true,
          },
        }),
        {}
      ),
      subtitle: `#${i + 1}`,
    }));

    this.setState({
      familyList,
      modalTitle,
    });
  }

  isFile(str) {
    return !!str.match(new RegExp(_enum.FILEIDPAT));
  }

  processData(data) {
    let columns = [];
    if (data.length > 0) {
      let dynamicFieldNames = new Map();
      data = data.map((row) => {
        let categoryInfoArr = [];
        (row?.answers || []).map((item) => {
          if (item.categoryInfo.length > 0) {
            categoryInfoArr.push(item);
          }
        });
        //console.log({ categoryInfoArr });
        return {
          ...row,
          categoryInfoArr,
          ...(row?.answers || []).reduce((c, v) => {
            dynamicFieldNames.set(v.fieldLabel, v.categoryInfo);
            return { ...c, [v.fieldLabel]: v.fieldResponse };
          }, {}),
        };
      });
      dynamicFieldNames = [...dynamicFieldNames].map(([key, value]) => ({
        key: key,
        categoryInfo: value,
      }));
      console.log({ items: data, dynamicFieldNames });
      let row = data[0];
      let displayColumns = [
        "createdBy",
        "createdByName",
        "formName",
        "createdOn",
        "updatedOn",
      ];

      columns = columns.concat(
        Object.entries(row)
          .filter(([key, value]) => displayColumns.includes(key))
          .map(([key, value]) => {
            if (key === "createdOn" || key === "updatedOn") {
              return {
                dataIndex: key,
                title: key.toTitleCase(),
                render: (text) => {
                  let dt = new Date(text);
                  if (!isNaN(dt.getTime())) {
                    return <>{dt.dateToDDMMYYYY(dt)}</>;
                  } else {
                    return <>{text}</>;
                  }
                },
              };
            } else {
              return {
                dataIndex: key,
                title: key.toTitleCase(),
              };
            }
          })
      );

      let dynamicColumns = dynamicFieldNames.map(({ key, categoryInfo }) => ({
        dataIndex: key,
        title: key,
        dynamicColumn: { categoryInfo, key },
        // ellipsis: {
        //   showTitle: false,
        // },
        // ellipsis: true,
        render: (text, record) => {
          console.log({ record });
          if (this.isFile(text)) {
            return (
              <a
                href={this.downloadFile(text)}
                className="link_btn"
                target="_blank"
              >
                {key} Download
              </a>
            );
          } else if (categoryInfo.length) {
            return (
              <Link
                className="link_btn"
                ref={this.memberModalRef}
                onClick={(e) => this.setFamilyMember(record, text, key)}
                data-bs-toggle="modal"
                data-bs-target="#family_members_modal"
              >
                {text}
              </Link>
            );
          } else if (key.camelCase() === "location") {
            return (
              <a
                target="_blank"
                href={"https://www.google.com/maps/@" + text + ",12z?entry=ttu"}
                className="link_btn"
              >
                {text}
              </a>
            );
          } else if (text?.includes("alh-hrms.s3")) {
            return (
              <a target="_blank" href={text} className="link_btn">
                Download Image
              </a>
            );
          } else if (
            new Date(`${text}`).toString() !== "Invalid Date" &&
            text.length > 5
          ) {
            return new Date(`${text}`).dateToDDMMYYYY(new Date(`${text}`));
          } else {
            if (typeof text === "string" && text.length > 25) {
              return (
                <span
                  className="text_truncate cursor-pointer"
                  title={text}
                  onClick={() => alert(text)}
                >
                  {text}
                </span>
              );
            } else {
              return text;
            }
          }
        },
      }));

      columns.splice(3, 0, ...dynamicColumns);
      // console.log({ columns, dynamicColumns });
      //for village index column
      let newIndex = 0; // New index position
      let villageIndex = columns.findIndex((v) => v.dataIndex === "Village");
      let districtIndex = columns.findIndex((v) => v.dataIndex === "District");
      let clusterIndex = columns.findIndex((v) => v.dataIndex === "Cluster");

      if (villageIndex !== -1) {
        let newColumns = [
          ...columns.slice(0, villageIndex),
          ...columns.slice(villageIndex + 1),
        ];

        columns = [
          ...newColumns.slice(0, newIndex),
          columns[villageIndex],
          ...newColumns.slice(newIndex),
        ];
      }
      if (districtIndex !== -1) {
        let newColumns = [
          ...columns.slice(1, districtIndex),
          ...columns.slice(districtIndex + 1),
        ];

        columns = [
          ...newColumns.slice(1, newIndex),
          columns[districtIndex],
          ...newColumns.slice(newIndex),
        ];
      }
      if (clusterIndex !== -1) {
        let newColumns = [
          ...columns.slice(2, clusterIndex),
          ...columns.slice(clusterIndex + 1),
        ];

        columns = [
          ...newColumns.slice(2, newIndex),
          columns[clusterIndex],
          ...newColumns.slice(newIndex),
        ];
      }

      console.log({ columns, dynamicColumns });

      // CHANGING TABLE TITLES
      columns.map((column) => {
        if (column.dataIndex === "createdBy") {
          column.title = "DS ID";
        }
        if (column.dataIndex === "createdByName") {
          column.title = "Name";
        }
        if (column.dataIndex === "formName") {
          column.title = "Form Name";
          column.render = (text) => {
            console.log({ formName: text });
            if (typeof text === "string" && text.length > 25) {
              return (
                <span
                  className="text_truncate cursor-pointer"
                  title={text}
                  onClick={() => alert(text)}
                >
                  {text}
                </span>
              );
            } else {
              return text;
            }
          };
        }
        if (column.dataIndex === "createdOn") {
          column.title = "Created On";
        }
        if (column.dataIndex === "updatedOn") {
          column.title = "Updated On";
        }
      });
    }
    return { data, columns };
  }

  fetchList() {
    this.api
      .apiGetDataCollectionFormResponse(this.state.search, {
        id: this.props.urlparams.id,
      })
      .then(({ data: resdata, total }) => {
        let { data, columns } = this.processData(resdata);
        this.setState({
          data,
          total,
          columns,
          rowcount: total,
        });
      });
  }

  editDCResponseForm(record) {
    this.navigate(
      `/datacollection/datacollectionresponseinfo?id=${this.props.urlparams?.id}&responseId=${record._id}`
    );
  }

  onPageChange(pageData) {
    this.setState(
      {
        search: {
          ...this.state.search,
          page: pageData.current,
          limit: pageData.pageSize || pageData.defaultPageSize || 25,
        },
      },
      (state) => this.fetchList()
    );
  }

  isFile(str) {
    return !!(str + "").match(new RegExp(_enum.FILEIDPAT));
  }

  downloadFile(fileId) {
    let authToken = window.store.getState().root.auth;
    // console.log({ authToken: authToken });
    return (
      app.api
        .request(app.apis().fileDownloader)
        .urltransform({ attachmentId: fileId }).url +
      "?auth=" +
      authToken
    );
  }

  getBackLink() {
    if (this.props.urlparams?.id) {
      let backLink = `/datacollection`;
      return backLink;
    } else {
      return "/datacollection";
    }
  }
  render() {
    let { columns, data, rowcount, familyList = [], modalTitle } = this.state;
    let { isAdmin, isManager } = this;
    return (
      <LayoutWrapper
        title="Data Collection Response"
        back="Data Collection"
        backlink={this.getBackLink()}
      >
        <div className="row">
          <div className="col"></div>
          <div className="mb-4">
            <button
              type="button"
              className={Object.className({
                "btn add-btn": true,
                // hidden: !isAdmin,
              })}
              onClick={() => this.getDownloadDates()}
            >
              Download
            </button>
          </div>
        </div>
        <div className="row">
          <div className="col-12 dc_table">
            {data.length > 0 ? (
              <AppTable
                data={data}
                columns={columns}
                onNext={() => this.onNext()}
                onPrev={() => this.onPrev()}
                onChange={(...arg) => this.onPageChange(...arg)}
                total={rowcount}
                reorder={true}
                copyAction={false}
                targetType="tap"
                editable={false}
                deletable={false}
                viewable={false}
                copyable={false}
                disableable={false}
                {...(isAdmin || isManager
                  ? {
                      // editable: true,
                      // editAction: (v) => this.editDCResponseForm(v),
                    }
                  : {
                      editable: false,
                      deletable: false,
                      viewable: false,
                      copyable: false,
                      disableable: false,
                    })}
              ></AppTable>
            ) : (
              <p>No data to display.</p>
            )}
          </div>
        </div>
        {console.log("familyList", familyList)}
        <FamilyMemberModel
          list={familyList}
          id="family_members_modal"
          title={modalTitle}
        />
      </LayoutWrapper>
    );
  }
}

export default connect(DataCollectionResponse);
