export default {
    apiEmployeeDataMoke() {
        let { Avatar_05, Avatar_02, Avatar_11, Avatar_12, Avatar_09, Avatar_10, Avatar_13 } = imagepaths;
        let data = [
            { id: 1, image: Avatar_02, name: "John Doe", role: "Web Designer", employee_id: "FT-0001", email: "johndoe@example.com", mobile: '9876543210', joindate: "1 Jan 2013", department: "Testing", location: "Benglore" },
            { id: 2, image: Avatar_05, name: "Richard Miles", role: "Web Developer", employee_id: "FT-0002", email: "richardmiles@example.com", mobile: '9876543210', joindate: "18 Mar 2014", department: "Develpment", location: "Benglore" },
            { id: 3, image: Avatar_11, name: "John Smith", role: "Android Developer", employee_id: "FT-0003", email: "johnsmith@example.com	", mobile: '9876543210', joindate: "1 Apr 2014", department: "IT", location: "Benglore" },
            { id: 4, image: Avatar_12, name: "Mike Litorus", role: "IOS Developer", employee_id: "FT-0004", email: "mikelitorus@example.com", mobile: '9876543210', joindate: "1 Apr 2014", department: "Deveops", location: "Benglore" },
            { id: 5, image: Avatar_09, name: "Wilmer Deluna", role: "Team Leader", employee_id: "FT-0005", email: "wilmerdeluna@example.com", mobile: '9876543210', joindate: "22 May 2014", department: "Human Resource", location: "Bomby" },
            { id: 6, image: Avatar_10, name: "Jeffrey Warden", role: "Web Developer", employee_id: "FT-0006", email: "jeffreywarden@example.com", mobile: '9876543210', joindate: "16 Jun 2013", department: "Admin", location: "Hydrabadh" },
            { id: 7, image: Avatar_13, name: "Bernardo Galaviz", role: "Web Developer", employee_id: "FT-0007", email: "bernardogalaviz@example.com", mobile: '9876543210', joindate: "1 Jan 2013", department: "Cleaning", location: "Mysore" },
        ];
        return this.mokeapi(() => (data), "Getting all employee data please wait...", 2000);
    },

    apiEmpDashboardDataMoke() {
        let data = {
            employee_details: {
                total_emplyees: "234",
                active_emplyees: "234",
                todays_present: "234",
                todays_absent: "234",
            },
            task_manager: {
                last_months: "2",
                total_task: "234",
                open_task: "234",
                closed_task: "234",
            }
        }

        return this.mokeapi(() => (data), "Getting dashboard data please wait...", 2000);

    },

    apiGetAdminDashboard() {
        return this.request(this.api().adminDashborad)
            .exec()
            .mapresults(response => {
                return response.data
            })
            .log()
            .get();

    },

    apiGetEmployeeDashboard() {
        return this.request(this.api().employeeDashborad)
            .exec()
            .mapresults(response => {
                return response.data
            })
            .log()
            .get();

    },
    apiGetExportEmployeeList(data) {
        return this.request(this.api().exportEmployeeList, data)
            .responsetype("arraybuffer")
            .exec().log().get();
    },

    apiGetExportEmployeeListByRole(data) {
        return this.request(this.api().exportEmployeeListByRole, data)
            .urltransform(data)
            .responsetype("arraybuffer")
            .exec().log().get();
    },

    apiEmployeeData(data) {
        return this.request(this.api().listEmployee, data)
            .exec().log().mapresults((res) => ({
                data: res.data.items[0].records.map(item => (
                    {
                        employee_id: item.employee_id,
                        employee_name: item.employee_name,
                        employee_role: item.employee_role,
                        mobile_num: item.mobile_num,
                        email_address: item.email_address,
                        project_id: item.project_id,
                        project_name: item.project_name,
                        address: item.address,
                        date_of_joining: item.date_of_joining,
                        supervisor_id: item.supervisor_id,
                        status: item?.status,
                        index: item?.index,
                    }
                )),
                total: res.data.items[0].pagination[0]?.totalRecords || 0
            })).get();
    },

    apiEmployeeArchiveData(data) {
        return this.request(this.api().listArchiveEmployee, data)
            .exec().log().mapresults((res) => ({
                data: res.data.items[0].records.map(item => (
                    {
                        employee_id: item.employee_id,
                        employee_name: item.employee_name,
                        employee_role: item.employee_role,
                        mobile_num: item.mobile_num,
                        email_address: item.email_address,
                        project_id: item.project_id,
                        project_name: item.project_name,
                        address: item.address,
                        date_of_joining: item.date_of_joining,
                        supervisor_id: item.supervisor_id,
                        status: item?.status,
                        index: item?.index,
                    }
                )),
                total: res.data.items[0].pagination[0]?.totalRecords || 0
            })).get();
    },
    apiDeleteEmployee(data) {
        return this.request(this.api().deleteEmployee, data)
            .urltransform(data)
            .exec("De-activating employee please wait..").log().get();

    },
    apiSoftDeleteEmployee(data, urldata = {}) {
        let payload = {
            ...Object.except(data, ["index"])
        }
        return this.request(this.api().softDeleteEmployee, payload)
            .urltransform(urldata)
            .exec("De-activating employee please wait..").log().get();

    },
    apiGetEmployeeById(urldata) {
        console.log({ apiGetEmployeeById: urldata });

        return this.request(this.api().getEmployeeById)
            .urltransform(urldata)
            .exec().log().mapresults((res) => ({
                data: res.data,
            })).get();
    },
    apiGetEmployeeByDelta(urldata) {
        console.log({ apiGetEmployeeByDelta: urldata });

        return this.request(this.api().getEmployeeByDelta)
            .urltransform(urldata)
            .exec().log().mapresults((res) => ({
                data: res.data,
            })).get();
    },
    apiGetEmployeeDetails(data) {
        console.log({ apiGetEmployeeDetails: data });

        return this.request(this.api().getEmployeeBySearch, data)
            .exec().log().mapresults((res) => ({
                data: res.data.items[0].records.map(item => (
                    {
                        address: item?.address,
                        age: `${new Date(item.date_of_birth).getAge()}` || '',
                        dateOfBirth: item.date_of_birth,
                        dateOfJoining: item.date_of_joining,
                        designation: item?.designation,
                        emailId: item?.email_address,
                        employeeId: item?.employee_id,
                        gender: item?.gender,
                        mobileNo: item?.mobile_num,
                        name: item?.employee_name,
                        panNo: item?.pan_number,
                        profile_picture: item?.profile_picture,
                        profile_picture_key: item?.profile_picture_key,
                        salaryType: item?.salaryType,
                        workLocation: item?.workLocation,
                        // supervisorId: item?.supervisor_id
                    }
                ))[0],
            })).get();
    },
    apiEmployeeByMultiRoles(data) {
        console.log({ apiEmployeeByMultiRoles: data });
        return this.request(this.api().getEmployeeByMultirole, data)
            .exec().log().mapresults((res) => ({
                data: res.data.items,
                // total: res.data.items[0].pagination[0]?.totalRecords || 0
            })).get();
    },
    apiEmployeeUploadedDocList() {
        let { Avatar_05, Avatar_02, Avatar_11, Avatar_12, Avatar_09, Avatar_10, Avatar_13 } = imagepaths;
        let data = [
            { id: 1, image: Avatar_02, name: "John Doe", role: "Web Designer", employee_id: "FT-0001", email: "johndoe@example.com", mobile: '9876543210', joindate: "1 Jan 2013", department: "Testing", location: "Benglore" },
            { id: 2, image: Avatar_05, name: "Richard Miles", role: "Web Developer", employee_id: "FT-0002", email: "richardmiles@example.com", mobile: '9876543210', joindate: "18 Mar 2014", department: "Develpment", location: "Benglore" },
            { id: 3, image: Avatar_11, name: "John Smith", role: "Android Developer", employee_id: "FT-0003", email: "johnsmith@example.com	", mobile: '9876543210', joindate: "1 Apr 2014", department: "IT", location: "Benglore" },
            { id: 4, image: Avatar_12, name: "Mike Litorus", role: "IOS Developer", employee_id: "FT-0004", email: "mikelitorus@example.com", mobile: '9876543210', joindate: "1 Apr 2014", department: "Deveops", location: "Benglore" },
            { id: 5, image: Avatar_09, name: "Wilmer Deluna", role: "Team Leader", employee_id: "FT-0005", email: "wilmerdeluna@example.com", mobile: '9876543210', joindate: "22 May 2014", department: "Human Resource", location: "Bomby" },
            { id: 6, image: Avatar_10, name: "Jeffrey Warden", role: "Web Developer", employee_id: "FT-0006", email: "jeffreywarden@example.com", mobile: '9876543210', joindate: "16 Jun 2013", department: "Admin", location: "Hydrabadh" },
            { id: 7, image: Avatar_13, name: "Bernardo Galaviz", role: "Web Developer", employee_id: "FT-0007", email: "bernardogalaviz@example.com", mobile: '9876543210', joindate: "1 Jan 2013", department: "Cleaning", location: "Mysore" },
        ];
        return this.mokeapi(() => (data), "Getting all uploaded files please wait...", 2000);
    },
    apiEmployeeDesignationsMoke() {
        let data = ["Web Developer", 'Android Developer', 'IOS Developer'].options();
        return this.mokeapi(() => (data), "Getting designations please wait...", 2000);
    },

    apiEmployeeDesignations(data = {}) {
        //return this.apiEmployeeDesignationsMoke();
        return this.request(this.api().designationList, data)
            .exec().log().mapresults((data) => {
                app.store("designationList", data.options('designation'))
                return data
            }).get();
    },
    apiCreateDesignation(data = {}) {
        return this.request(this.api().createDesignation, data)
            .exec("Adding Designation please wait...").log().mapresults(async (data) => {
                await this.apiEmployeeDesignations();
                return data
            }).get();
    },
    apiEditDesignation(data = {}, urldata = {}) {
        return this.request(this.api().editDesignation, data)
            .urltransform(urldata)
            .exec("Updating Designation please wait...").log().mapresults(async (data) => {
                await this.apiEmployeeDesignations();
                return data
            }).get();
    },
    apiDeleteDesignation(data = {}, urldata = {}) {
        return this.request(this.api().deleteDesignation, data)
            .urltransform(urldata)
            .exec("Deleting Designation please wait...").log().mapresults(async (data) => {
                await this.apiEmployeeDesignations();
                return data
            }).get();
    },
    apiEmployeeDepartment(data = {}) {
        return this.request(this.api().departmentsList, data)
            .exec().log().mapresults((data) => {
                app.store("departmentsList", data.options('department'))
                return data
            }).get();
    },

    apiEmployeeWorkLocation(data = {}, store = true) {
        return this.request(this.api().worklocationList, data)
            .exec().log().mapresults((data) => {
                store && app.store("worklocationList", data.result.options('location'));
                return data
            }).get();
    },
    apiCreateWorkLocation(data = {}) {
        return this.request(this.api().createWorklocation, data)
            .exec().log().get();
    },
    apiUpdateWorkLocation(data = {}, urldata = {}) {
        return this.request(this.api().updateWorklocation, data)
            .urltransform(urldata)
            .exec().get();
    },
    apiDeleteWorkLocation(data = {}, urldata = {}) {
        return this.request(this.api().deleteWorklocation, data)
            .urltransform(urldata)
            .exec().get();
    },
    apiEmployeeHolidayList(data = {}) {
        return this.request(this.api().holidayList, data)
            .exec().log().mapresults((data) => {
                app.store("holidayList", data)
                return data
            }).get();
    },
    apiAddHoliday(data = {}) {
        return this.request(this.api().addHoliday, data)
            .exec("Adding holiday please wait...").log().mapresults((data) => {
                this.apiEmployeeHolidayList();
                return data
            }).get();
    },
    apiEditHoliday(data = {}, urldata = {}) {
        return this.request(this.api().editHoliday, data)
            .urltransform(urldata)
            .exec("Updating holiday please wait...").log().mapresults(async (data) => {
                await this.apiEmployeeHolidayList();
                return data
            }).get();
    },
    apiDeleteHoliday(data = {}, urldata = {}) {
        return this.request(this.api().deleteHoliday, data)
            .urltransform(urldata)
            .exec("Deleting holiday please wait...").log().mapresults(async (data) => {
                await this.apiEmployeeHolidayList();
                return data
            }).get();
    },
    apiGetEmployeeByRole(role) {
        console.log({ apiGetEmployeeByRole: role });
        return this.request(this.api().getEmployeeByrole)
            .urltransform({
                employee_role: role,
            })
            .exec()
            .mapresults(response => {
                return response.data.items
            })
            .log()
            .get();

    },
    apiCreateEmployee(data) {
        return this.request(this.api().createEmployee, data)

            .transform((post) => (
                {
                    employee_id: post.employee_id,
                    employee_name: post.employee_name,
                    mobile_num: post.mobile_num,
                    email_address: post.email_address,
                    password: post.temp_password,
                    supervisor_id: post.supervisor_id,
                    // 
                    employee_role: post.employee_role,
                    gender: post.gender,
                    date_of_birth: post.date_of_birth,
                    date_of_joining: post.date_of_joining,
                    address: post.address,
                    project_id: post.project_id,
                    project_name: post.project_name,
                    district: post.district,
                    cluster_code: post.cluster_code,
                    village_id: post.village_id,
                    village_name: post.village_name,
                    // new flags
                    monthly_salary: post?.monthly_salary,
                    business_name: post?.business_name,
                    digital_sakhi_id: post?.digital_sakhi_id,
                    gst: post?.gst,
                    pan_card_num: post?.pan_card_num,
                    aadhar_card_num: post?.aadhar_card_num,
                    entrepreneur_business_type: post?.entrepreneur_business_type,
                    financial_yr_turnover: post?.financial_yr_turnover,
                    edp_training_date: post?.edp_training_date,
                    business_established_date: post?.business_established_date,
                    gap_funding: post?.gap_funding,
                }
            ))
            .exec().log()
            .get();
    },
    apiUpdateEmployee(data, urldata) {
        console.log("apiUpdateEmployee", data, urldata);

        data = {
            ...Object.except(data, ["supervisor_name"]),
        }
        return this.request(this.api().updateEmployee, data)
            .urltransform(urldata)
            .exec("Updating Employee please wait...").log().mapresults(async (data) => {
                return data
            }).get();
    },
    apiUpdateEmployeeRemark(data, urldata) {
        console.log("apiUpdateEmployeeRemark", data, urldata);
        return this.request(this.api().updateEmployeeRemark, data)
            .urltransform(urldata)
            .exec("Updating Employee Remark please wait...").log().mapresults(async (data) => {
                return data
            }).get();
    },
    apiCreateEmployeeAdmin(data) {
        console.log("apiCreateEmployeeAdmin", data);
        return this.request(this.api().createEmployeeAdmin, data)
            .transform((post) => ({
                employee_id: post.employee_id,
                employee_name: post.employee_name,
                mobile_num: post.mobile_num,
                email_address: post.email_address,
                password: post.password,
            })).exec().log().mapresults((data) => {
                return data
            }).get();
    },
    apiCreateMasterData(data) {
        return this.request(this.api().createMasterData, data)
            .transform((post) => (
                {
                    district: post.district,
                    cluster_code: post.cluster_code,
                    village_id: post.village_id,
                    village_name: post.village_name,
                }
            ))
            .exec().log().mapresults((data) => {
                return data
            }).get();
    },

    apiSalaryBreakdown(value) {
        return this.request(this.api().salaryBreakdown, {
            fixed_salary_pa: value
        })
            .exec().log().get();
    },
};