export default {
  apiCreateDataCollectionForm(data) {
    console.log({ apiCreateDataCollectionForm: data });
    let payload = {
      formName: data.formName,
      role: data?.role?.split(','),//"test,erwer"
      projectId: data.projectId,
      projectName: data.projectName,
      language: data.language.split(",").map((lang) => lang.trim()),
      formDescription: data.formDescription,
      updatedBy: data.updatedBy,
      fields: Object.values(data?.fields || []).filter((v) => v).map((field) => {
        let hasSubCategory = ['true'].includes(field?.subCategory + '');
        return {
          label: field.label,
          fieldType: field.fieldType,
          possibleValues: (field?.possibleValues || "").split(',').filter(v => v).map(v => v.trim()),
          fieldConstraint: (field?.fieldConstraint || '').split(','),
          constraintMetadata: field.constraintMetadata || {},
          subCategory: ['true'].includes(field?.subCategory + ''),
          ...(hasSubCategory ? {
            categoryInfo: (field?.categoryInfo || []).map(o => ({
              ...o,
              fieldConstraint: o.fieldConstraint.split(','),
              constraintMetadata: o.constraintMetadata || {},
              possibleValues: (o?.possibleValues || "").split(',').filter(v => v).map(v => v.trim())
            }))
          } : {})
        };
      }),
    };
    // ),
    // };

    return this.request(this.api().dataCollectionForm, payload).exec().log().get();
  },

  editDCForm(data, urldata = mixins.params()) {
    let payload = {
      role: data.role,
      language: data.language.split(",").map(lang => lang.trim()),
      fields: Object.values(data?.fields || []).map((field) => {
        // const fieldConstraint = (['checkbox', 'radio', 'select', 'file', 'geolocation', 'date'].includes(field.fieldType)) ? ['mandatory'] : (field.fieldConstraint || '').split(',');
        return {
          label: field.label,
          fieldType: field.fieldType,
          // fieldConstraint: fieldConstraint,
          fieldConstraint: (field?.fieldConstraint || '').split(','),
        };
      })
    };
    return this.request(this.api().updatedataCollectionForm, payload).urltransform(urldata).exec().log().get();
  },

  apiDataCollectionFormList(data) {
    let payload = {
      ...data
    };
    console.log("apiDataCollectionForm: ", payload);
    return this.request(this.api().dataCollectionList, payload).exec().log()
      .mapresults((response) => {
        return {
          data: response.data.records.map((form) => ({
            id: form._id,
            formName: form.formName,
            role: form?.role instanceof Array ? form.role.join(', ') : form.role,
            language: form.language.map(lang => _enum.APPLICANT_DC_LANGUAGE_LIST.find(v => v.key == lang)?.label || "")
              .join(","),
            projectId: form.projectId,
            updatedBy: form.updatedBy,
            projectName: form.project_name,
            projectManagerName: form.project_managers?.map(v => v.employee_name).join(', '),
            createdOn: form.createdOn,
            updatedOn: form.updatedOn,
            numberOfResponse: form.numberOfResponse,
            disable: form?.disable,
            exportFileCreationDTM: form.exportFileCreationDTM,
            exportFileDownload: form.exportFileDownload,
          })),
          total: response.data.pagination[0]?.totalRecords || 0
        }
      })
      .get();
  },

  apiUpdateDataCollectionForm(data, urldata = mixins.params()) {
    let payload = {
      role: data.role.split(','),
      // projectId: data.projectId,
      language: data.language?.split(",").map(lang => lang.trim()),
      fields: Object.values(data?.fields || []).map((field) => {
        let hasSubCategory = ['true'].includes(field?.subCategory + '')
        return {
          label: field.label,
          fieldType: field.fieldType,
          possibleValues: (field?.possibleValues || "").split(',').filter(v => v).map(v => v.trim()),
          fieldConstraint: (field?.fieldConstraint || '').split(','),
          constraintMetadata: field.constraintMetadata || {},
          subCategory: hasSubCategory,
          ...(hasSubCategory ? {
            categoryInfo: (field?.categoryInfo || []).map(o => ({
              ...o,
              fieldConstraint: o.fieldConstraint.split(','),
              constraintMetadata: o.constraintMetadata || {},
              possibleValues: (o?.possibleValues || "").split(',').filter(v => v).map(v => v.trim())
            }))
          } : {})
        };
      }),
      disable: data?.disable
    };
    console.log("apiUpdateDataCollectionForm: ", payload);
    return this.request(this.api().updatedataCollectionForm, payload).urltransform(urldata).exec().log().get();
  },


  apiGetDataCollectionForm(data = {}) {
    //  debugger
    let payload = {
      // "isActive": true,
      ...data
    };
    return this.request(this.api().getDCForm, payload)
      .urltransform()
      .exec().log()
      .mapresults((response) => {

        let data = response.data;

        return {
          formName: data.formName,
          role: data.role,
          language: data.language,
          projectId: data.projectId,
          formDescription: data.formDescription,
          updatedBy: data.updatedBy,
          disable: data?.disable,
          fields: (data?.fields || []).map((field) => ({

            id: field._id,
            label: field.label,
            fieldType: field.fieldType,
            updatedBy: field.updatedBy,
            fieldConstraint: (field.fieldConstraint || []).join(','),
            constraintMetadata: field.constraintMetadata || {},
            possibleValues: (field?.possibleValues || [])?.join(','),
            subCategory: ['true'].includes(field?.subCategory + ''),
            categoryInfo: field?.categoryInfo ? (field?.categoryInfo) : undefined,
          })
          )
        };

      }).get();
  },

  apiGetDataCollectionFormResponse(data = {}, urldata = {}) {
    // console.log({ apiGetDataCollectionFormResponse: data, urldata });
    let payload = {
      // "isActive": true,
      ...data
    };
    return this.request(this.api().getDCFormResponse, payload)
      .urltransform(urldata)
      .exec().log()
      .mapresults(({ data, total }) => {
        return {
          data: data.items[0]?.records,
          total: data.items[0].pagination[0]?.totalRecords
        };
      }).get();
  },

  apiGetDataCollectionFormResponseDetails(urldata = {}) {
    return this.request(this.api().getDCFormResponseDetails)
      .urltransform(urldata)
      .exec().log()
      .mapresults((res) => {
        return res?.data
      }).get();
  },

  apiDeleteDCForm(urldata) {
    const payload = {
      isActive: false
    };

    return this.request(this.api().deleteDCForm, payload)
      .urltransform(urldata)

      .exec().log().get();
  },
  apiDisableDCForm(urldata) {
    const payload = {
      disable: true
    };
    return this.request(this.api().disableDCForm, payload)
      .urltransform(urldata)
      .exec().log().get();
  },
  apiEnableDCForm(urldata) {
    const payload = {
      disable: false
    };
    return this.request(this.api().enableDCForm, payload)
      .urltransform(urldata)
      .exec().log().get();
  },
  apiDcDashboardData(data = {}) {
    return this.request(this.api().dcdashboardData, data)
      .exec()
      .log()
      .get();
  },
  apiDCFileUploader(data, urldata = mixins.params()) {
    console.log({ filedata: data });
    return this.request(this.api().fileuploader, data)
      .urltransform(urldata)
      .multipart()
      .exec()
      .log()
      .get();
  },
  apiDCFileDownloader(data, urldata = mixins.params()) {
    console.log({ filedata: data });
    return this.request(this.api().fileDownloader, data)
      .urltransform(urldata)
      .multipart()
      .exec()
      .log()
      .get();
  },
}