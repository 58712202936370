import React, { Component } from "react";
import LayoutWrapper from "../../LayoutWrapper";
import * as XLSX from "xlsx";

export class EmployeeLogList extends Component {
  constructor(props) {
    super(props);
    props.initialconstruct.bind(this)("EmployeeLogList");
    this.state = {
      rowcount: 0,
      data: [],
      search: {
        limit: 10,
        order_by: "updatedAt",
        order_direction: "DESC",
        page: 1,
      },
    };
    this.columns = [
      {
        title: "Date",
        dataIndex: "date",
        render: (text) => {
          return <>{text?.replace(/\//g, "-")}</>;
        },
      },
      {
        title: "Total Km.",
        dataIndex: "total_kilometer",
        render: (text) => {
          return <>{text?.toLocaleString("en-IN")}</>;
        },
      },
      {
        title: "Amount",
        dataIndex: "amount",
        render: (text) => {
          return <>{text?.cur("", "INR", "en-US")}</>;
        },
      },
      {
        title: "Vehicle Type",
        dataIndex: "vehicle_type",
        render: (text) => {
          return <>{_enum.VEHICLE_TYPES[text]}</>;
        },
      },
      {
        title: "From Location",
        dataIndex: "from_location",
      },
      {
        title: "To Location",
        dataIndex: "to_location",
      },
      {
        title: "Vehicle Number",
        dataIndex: "vehicle_number",
      },
      {
        title: "Start Meter",
        dataIndex: "start_meter",
      },
      {
        title: "End Meter",
        dataIndex: "end_meter",
      },
      {
        title: "Latitude",
        dataIndex: "start_latitute",
      },
      {
        title: "Longitude",
        dataIndex: "start_longitute",
      },
    ];
  }

  fetchList() {
    let payload = {
      ...this.state.search,
      employee_id: this.props.urlparams?.employee_id,
      month_year: this.props.urlparams?.month_year,
    };
    this.props.api.apiGetLogsByMonth(payload).then(({ data, total }) => {
      console.log({ apiGetLogsByMonth: data, total });
      this.setState({
        data,
        rowcount: total,
      });
    });
  }

  handleLogDelete(record) {
    let payload = {
      log_book_id: record?.log_book_id,
    };

    confirm(`Are you want to delete this log?`).promise.then(() => {
      this.api.apiDeleteLogbook(payload).then(() => {
        this.fetchList();
      });
    });
  }

  componentDidMount() {
    this.fetchList();
  }

  getBreadCums() {
    if (this.props.urlparams?.employee_id) {
      return `Log Book / ${this.props.urlparams?.employee_name
        ?.spaceCase("_")
        .toTitleCase()}`;
    } else {
      return "Courses";
    }
  }

  getBackLink() {
    if (this.props.urlparams?.employee_id) {
      let backLink = `/admin/logbook`;
      return backLink;
    } else {
      return "/admin/logbook";
    }
  }
  onPageChange(pageData) {
    this.setState(
      {
        search: {
          ...this.state.search,
          page: pageData.current,
          limit: pageData.pageSize || pageData.defaultPageSize || 25,
        },
      },
      () => this.fetchList()
    );
  }

  getDownloadData() {
    let { rowcount, search } = this.state;
    let pageCount = Math.ceil(rowcount / search.limit);
    console.log(pageCount);
    let promises = [];
    promises.length = pageCount;
    promises.fill(0);
    console.log(promises);

    let payload = {
      ...this.state.search,
      employee_id: this.props.urlparams?.employee_id,
    };
    promises = promises.map((v, i) =>
      this.api.apiGetLogsByMonth({
        ...payload,
        page: i + 1,
      })
    );
    console.log(promises);
    Promise.all(promises).then((results) => {
      let totalrows = results.reduce((col, item) => col.concat(item.data), []);
      // let { data } = this.processData(totalrows);
      this.handleDownload(totalrows);
    });
  }

  handleDownload = (totaldata = []) => {
    const { data: pagedata, columns } = this.state;
    let data = totaldata.length ? totaldata : pagedata;

    // CONVERT RESPONSE DATA
    let modifiedData = totaldata.map((obj) => {
      // Combine "start_latitute" and "start_longitute" as "start-latitude/longitude"

      obj["start-latitude/longitude"] =
        obj.start_latitute == undefined || obj.start_longitute === undefined
          ? "-"
          : `${obj.start_latitute} / ${obj.start_longitute}`;

      obj["end-latitude/longitude"] =
        obj.end_latitute == undefined || obj.end_longitute === undefined
          ? "-"
          : `${obj.end_latitute} / ${obj.end_longitute}`;

      // Remove the individual properties
      delete obj.start_latitute;
      delete obj.start_longitute;
      delete obj.end_latitute;
      delete obj.end_longitute;

      return obj;
    });
    // console.log({ modifiedData });

    const tabledata = modifiedData.reduce((arr, row) => {
      let mrow = Object.only(row, [
        "date",
        "total_kilometer",
        "amount",
        "vehicle_type",
        "from_location",
        "to_location",
        "vehicle_number",
        "start_meter",
        "end_meter",
        "start-latitude/longitude",
        "end-latitude/longitude",
      ]);
      arr.push(mrow);
      return arr;
    }, []);
    let groups = {};
    let addeddata = tabledata.reduce((arr, trow, index) => {
      let row = data[index];
      let isRowHasArray = Object.values(trow).reduce(
        (flag, value) => flag || value instanceof Array,
        false
      );
      let arrRemovedRow = Object.filter(trow, (v) => !(v instanceof Array));
      arrRemovedRow = Object.filter(
        arrRemovedRow,
        (v, k) => !["projectId", "formId"].includes(k)
      );
      arr.push(arrRemovedRow);
      if (isRowHasArray) {
        let formId = row._id;
        let formName = row.formName;
        let arrayValues = Object.filter(trow, (v) => v instanceof Array);
        Object.entries(arrayValues).map(([key, values], j) => {
          groups[formId + "-" + j] = groups[formId + "-" + j] || [];
          values.map((value) => {
            groups[formId + "-" + j].push({
              id: formId,
              formName,
              subcategory: key,
              ...value,
            });
          });
        });
      }
      return arr;
    }, []);
    let groupsarr = Object.entries(groups).reduce((arr, [key, values]) => {
      arr = arr.concat(values);
      return arr;
    }, []);
    // console.log("data", data, { tabledata, addeddata, groups, groupsarr });
    // console.log("columns", columns);
    let orderedHeaders = [
      "date",
      "from_location",
      "to_location",
      "start_meter",
      "end_meter",
      "total_kilometer",
      "amount",
      "vehicle_type",
      "vehicle_number",
      "start-latitude/longitude",
      "end-latitude/longitude",
    ];
    let mainHeaders = Object.keys(addeddata[0] || {});
    mainHeaders = [
      ...orderedHeaders,
      ...mainHeaders.filter((k) => !orderedHeaders.includes(k)),
    ];

    let mainData = addeddata.map((v) => mainHeaders.map((k) => v[k]));
    let groupHeaders = Object.keys(groupsarr[0] || {});
    let groupData = groupsarr.map((v) => groupHeaders.map((k) => v[k]));
    let concatedHeader = [...mainHeaders, ...groupHeaders.slice(2)];
    let concatedData = mainData.reduce((c, r) => {
      c.push(r);
      let grouprows = groupData.filter((o) => o[0] == r[0]);
      if (grouprows.length) {
        grouprows.map((gr) => {
          c.push([...r, ...gr.slice(2)]);
        });
      }
      return c;
    }, []);
    // console.log({ concatedHeader, concatedData });

    const exportData = [concatedHeader, ...concatedData];

    console.log("mainheaders", {
      mainHeaders,
      addeddata,
      tabledata,
      exportData,
    });
    //return;

    // Create a new workbook and worksheet
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.aoa_to_sheet(exportData);

    // Add the worksheet to the workbook
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

    // Generate a unique filename for the Excel sheet
    const filename = `employee_loglist_${new Date().getTime()}.xlsx`;

    // Export the workbook as an Excel file

    XLSX.writeFile(workbook, filename);
  };

  render() {
    let { data } = this.state;
    let { columns } = this;
    return (
      <LayoutWrapper
        title="Log Book"
        back={this.getBreadCums()}
        backlink={this.getBackLink()}
      >
        <div className="row">
          <div className="col"></div>
          <div className="mb-4">
            <button
              type="button"
              className="btn add-btn"
              onClick={() => this.getDownloadData()}
            >
              <i class="fa fa-download me-2"></i>
              Download
            </button>
          </div>
        </div>
        <div className="row mb-4">
          <div className="col">
            <AppTable
              data={data}
              columns={columns}
              reorder={true}
              onChange={(...arg) => this.onPageChange(...arg)}
              total={this.state.rowcount}
              editable={false}
              renderAction={(v) => v.list_top === true}
              deleteAction={(...args) => this.handleLogDelete(...args)}
              viewable={false}
              pageSize={10}
            ></AppTable>
          </div>
        </div>
      </LayoutWrapper>
    );
  }
}

export default connect(EmployeeLogList);
