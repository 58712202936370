import { Component } from "react";
import * as XLSX from "xlsx";
import EmployeeHeader from "./EmployeeHeader";
import EmployeeSearch from "./EmployeeSearch";

export class Employeelist extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rowcount: 0,
      employeeData: [],
      search: {
        limit: 20,
        order_by: "updatedAt",
        order_direction: "DESC",
        page: 1,
        is_deleted: !!this.props?.urlparams?.is_deleted,
      },
      selectedToggleList: "delete-false",
      isSoftDelete: true,
    };
    this.actionFormShow = false;
    this.fields = [
      {
        name: "lastdate",
        label: "Select Last Working Date",
        type: "date",
        value: new Date().toISOString().slice(0, 10),
        className: "select-focus",
      },
      {
        name: "remarks",
        label: "Remarks",
        type: "text",
        className: "select-focus",
      },
    ];
    this.columns = [
      {
        title: "Index",
        dataIndex: "index",
      },
      {
        title: "Role",
        dataIndex: "employee_role",
      },
      {
        title: "Name",
        dataIndex: "employee_name",
      },
      {
        title: "Employee ID",
        dataIndex: "employee_id",
      },

      {
        title: "Email ID",
        dataIndex: "email_address",
      },

      {
        title: "Mobile No.",
        dataIndex: "mobile_num",
      },

      {
        title: "Join Date",
        dataIndex: "date_of_joining",
        className: "text-center",
        render: (text) => {
          return (
            <>{text ? new Date(text).dateToDDMMYYYY(new Date(text)) : "-"}</>
          );
        },
      },
      {
        title: "Status",
        dataIndex: "status",
        render: (text, record) => {
          return (
            <span
              className={`status_text ${_enum.EMPLOYEE_STATUS_COLOR_MAP[text]}`}
            >
              {text?.toTitleCase() || "-"}
            </span>
          );
        },
      },
    ];
  }

  get isAdmin() {
    return this.props.store.user?.employee_role === "admin";
  }
  get currentLoginUser() {
    return this.props?.store.user;
  }

  fetchList(params = {}, reset) {
    console.log({ params });
    let payload = {};
    if (reset) {
      const { employee_id, ...newState } = this.state.search;
      this.setState({
        search: newState,
      });

      payload = {
        ...Object.except(this.state.search, [
          "employee_id",
          "employee_name",
          "employee_role",
        ]),
        ...params,
      };
    } else {
      payload = {
        ...this.state.search,
        ...params,
      };
    }

    if (!this.isAdmin) {
      payload.supervisor_id = this.props.store.user.employee_id;
    }

    console.log({ payload, search: this.state.search, params });

    if (payload?.is_deleted) {
      let archivePayload = {
        ...payload,
      };
      delete archivePayload.is_deleted;
      this.props.api.apiEmployeeArchiveData(archivePayload).then((res) => {
        console.log({ apiEmployeeArchiveData: res });
        this.setState({
          rowcount: res.total,
          employeeData: res.data,
        });
      });
    } else {
      this.props.api.apiEmployeeData(payload).then((res) => {
        console.log({ apiEmployeeData: res });
        this.setState({
          rowcount: res.total,
          employeeData: res.data,
        });
      });
    }
  }

  componentDidMount() {
    console.log({ componentDidMount: "EmployeeList" });
    window.EmployeeList = this;
    if (this.props.inactive === true) {
      this.onSearch({ status: "disabled" });
    } else {
      this.fetchList();
    }
  }

  onSearch(data, reset) {
    console.log("onSearch on list", data, reset);
    if (Object.keys(data).length === 0) {
      this.setState(
        {
          search: {
            ...data,
            ...this.state.search,
          },
        },
        () => this.fetchList()
      );
    } else {
      this.setState(
        {
          search: {
            ...data,
            ...this.state.search,
          },
        },
        () => this.fetchList(data, reset)
      );
    }
  }

  onFilterChange(field, value, firstDate, lastDate, displayValue) {}

  onPageChange(pageData) {
    console.log({ onPageChange: pageData });
    this.setState(
      {
        search: {
          ...this.state.search,
          page: pageData.current,
          limit: pageData.pageSize || pageData.defaultPageSize || 25,
        },
      },
      () => this.fetchList()
    );
  }

  editEmployee(record) {
    console.log("editEmployee", record);
    let url = "/admin/employee/onboarding?emp_id=" + record.employee_id;
    this.props.navigate(url);
  }
  viewEmployee(record) {
    this.props.navigate(
      `/admin/profile/employee-profile?${Object.QueryString({
        emp_id: record.employee_id,
        emp_name: record.employee_name,
      })}`
    );
  }
  deleteEmployee(record) {
    let payload = {
      ...Object.except(record, ["employee_id"]),
      is_deleted: true,
    };
    // console.log({ deleteEmployee: record, payload });

    let { is_deleted } = this.state.search;
    // console.log(is_deleted);

    // if (is_deleted) {
    //   console.log("hard delete");
    //   let cnfrm = confirm(
    //     `Do you really want to hard delete this ${record.employee_name} ?`
    //   );
    //   cnfrm.promise.then(() => {
    //     this.props.api
    //       .apiDeleteEmployee({ employee_id: record.employee_id })
    //       .then(() => {
    //         success("Employee deleted successfully");
    //         this.fetchList();
    //       });
    //   });
    // } else {
    //   let softDeletePayload = {
    //     ...Object.except(payload, [
    //       "status",
    //       "mobile_num",
    //       "email_address",
    //       "employee_role",
    //       "employee_id",
    //     ]),
    //   };
    //   console.log("soft delete", softDeletePayload);
    //   let cnfrm = confirm(
    //     `Do you really want to soft delete this ${record.employee_name} ?`
    //   );
    //   cnfrm.promise.then(() => {
    //     this.props.api
    //       .apiSoftDeleteEmployee(softDeletePayload, {
    //         employee_id: record.employee_id,
    //       })
    //       .then(() => {
    //         success("Employee deleted successfully");
    //         this.fetchList();
    //       });
    //   });
    // }
    let cnfrm = confirm(
      `Do you really want to hard delete this ${record.employee_name} ?`
    );
    cnfrm.promise
      .then(() => {
        this.props.api
          .apiDeleteEmployee({ employee_id: record.employee_id })
          .then(() => {
            success("Employee deleted successfully");
            this.fetchList();
          });
      })
      .catch((err) => {
        console.log({ err });
      });
  }

  getDownloadData() {
    let payload = {
      ...mixins.params(),
      ...this.state.search,
    };

    if (!this.isAdmin) {
      payload.supervisor_id = this.props.store.user.employee_id;
    }

    this.props.api.apiGetExportEmployeeList(payload).then(async (res) => {
      if (res) {
        let data = await res;
        let blob = new Blob([data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        let link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.setAttribute(
          "download",
          `employee_list_${new Date().toDate()}.xlsx`
        );
        link.click();
      }
    });
  }

  handleDownload = (totaldata = []) => {
    const { data: pagedata, columns } = this.state;
    let data = totaldata.length ? totaldata : pagedata;
    const tabledata = data.reduce((arr, row) => {
      let mrow = Object.only(row, [
        "employee_role",
        "employee_name",
        "employee_id",
        "email_address",
        "mobile_num",
        "date_of_joining",
        "address",
      ]);
      arr.push(mrow);
      return arr;
    }, []);
    let groups = {};
    let addeddata = tabledata.reduce((arr, trow, index) => {
      let row = data[index];
      let isRowHasArray = Object.values(trow).reduce(
        (flag, value) => flag || value instanceof Array,
        false
      );
      let arrRemovedRow = Object.filter(trow, (v) => !(v instanceof Array));
      arrRemovedRow = Object.filter(
        arrRemovedRow,
        (v, k) => !["projectId", "formId"].includes(k)
      );
      arr.push(arrRemovedRow);
      if (isRowHasArray) {
        let formId = row._id;
        let formName = row.formName;
        let arrayValues = Object.filter(trow, (v) => v instanceof Array);
        Object.entries(arrayValues).map(([key, values], j) => {
          groups[formId + "-" + j] = groups[formId + "-" + j] || [];
          values.map((value) => {
            groups[formId + "-" + j].push({
              id: formId,
              formName,
              subcategory: key,
              ...value,
            });
          });
        });
      }
      return arr;
    }, []);
    let groupsarr = Object.entries(groups).reduce((arr, [key, values]) => {
      arr = arr.concat(values);
      return arr;
    }, []);
    // console.log("data", data, { tabledata, addeddata, groups, groupsarr });
    // console.log("columns", columns);
    let mainHeaders = Object.keys(addeddata[0] || {});

    let mainData = addeddata.map((v) => mainHeaders.map((k) => v[k]));
    let groupHeaders = Object.keys(groupsarr[0] || {});
    let groupData = groupsarr.map((v) => groupHeaders.map((k) => v[k]));
    let concatedHeader = [...mainHeaders, ...groupHeaders.slice(2)];
    let concatedData = mainData.reduce((c, r) => {
      c.push(r);
      let grouprows = groupData.filter((o) => o[0] == r[0]);
      if (grouprows.length) {
        grouprows.map((gr) => {
          c.push([...r, ...gr.slice(2)]);
        });
      }
      return c;
    }, []);
    concatedHeader = concatedHeader.map((item) =>
      item.spaceCase("_").toTitleCase()
    );
    const exportData = [concatedHeader, ...concatedData];
    console.log("mainheaders", {
      mainHeaders,
      addeddata,
      tabledata,
      exportData,
      concatedHeader,
    });
    //return;

    // Create a new workbook and worksheet
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.aoa_to_sheet(exportData);

    // Add the worksheet to the workbook
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

    // Generate a unique filename for the Excel sheet
    const filename = `employeelist_${new Date().getTime()}.xlsx`;

    // Export the workbook as an Excel file

    XLSX.writeFile(workbook, filename);
  };

  handleToggleList(e) {
    let checked = document.querySelector('[name="togglelist"]').checked;
    console.log({ checked });

    if (checked) {
      this.setState({
        selectedToggleList: e.target.id,
        search: {
          ...this.state.search,
          is_deleted: false,
        },
      });
    } else {
      this.setState({
        selectedToggleList: e.target.id,
        search: {
          ...this.state.search,
          is_deleted: true,
        },
      });
    }
    this.fetchList();
  }

  handleStatusChange(e) {
    let value = JSON.parse(e[0].key);
    let url = "/admin/employee?" + (value ? "is_deleted=true" : "");
    navigate(url);
    // this.store("employee.is_deleted", data.language.split(","));
    // console.log(value);

    this.setState(
      {
        search: {
          ...this.state.search,
          is_deleted: value,
        },
        isSoftDelete: value,
      },
      (state) => this.fetchList()
    );
  }

  render() {
    let { employeeData, search } = this.state;
    let { is_deleted } = search;
    let { columns, isAdmin } = this;
    return (
      <>
        {isAdmin && (
          <EmployeeHeader
            is_deleted={this.state.search.is_deleted}
            handleDownloadList={() => this.getDownloadData()}
            handleToggleList={(e) => this.handleToggleList(e)}
            handleStatusChange={(e) => this.handleStatusChange(e)}
            selectedToggleList={this.state.selectedToggleList}
          />
        )}
        <EmployeeSearch
          inactive={this.props.inactive}
          onSearch={(...arg) => this.onSearch(...arg)}
        />
        <div className="row mb-4">
          <div className="col">
            <AppTable
              data={employeeData}
              columns={columns}
              reorder={true}
              onChange={(...arg) => this.onPageChange(...arg)}
              total={this.state.rowcount}
              {...(isAdmin
                ? {
                    // editAction: (v) => this.editEmployee(v),
                    editable: false,
                    viewAction: (v) => this.viewEmployee(v),
                  }
                : {
                    editable: false,
                    deletable: false,
                    viewable: false,
                  })}
              {...(isAdmin && !is_deleted
                ? {
                    deleteAction: (v) => this.deleteEmployee(v),
                  }
                : {
                    deletable: false,
                  })}
              pageSize={10}
            ></AppTable>
          </div>
        </div>
      </>
    );
  }
}

export default connect(Employeelist);
