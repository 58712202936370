import React, { Component } from "react";

export class DataCollectionSearch extends Component {
  constructor(props) {
    super(props);
    props.initialconstruct.bind(this)("");
    this.state = {
      search: {
        limit: 20,
        orderBy: "updatedAt",
        orderDirection: "DESC",
        page: 1,
      },
      selectedProject: "",
      selectedStatus: "",
      projectLists: [],
    };
    this.fields = [
      {
        name: "disable",
        label: "Status",
        type: "select",
        options: _enum.STATUS_ENABLE_DISABLE,
        className: "select-focus",
      },
      {
        name: "projectId",
        label: "Projects",
        type: "select",
        options: [],
        className: "select-focus mw-200-input",
      },
    ];
  }

  get isAdmin() {
    return this.props.store.user?.employee_role === "admin";
  }

  get isManager() {
    return this.props.store.user?.employee_role === "manager";
  }

  componentDidMount() {
    this.fetchProjectName();
  }

  fetchProjectName() {
    this.props.api.apiGetProjectsList().then(({ data, total }) => {
      this.fields.map((field) => {
        if (field?.name === "projectId") {
          field.options = data?.options("project_id", "project_name");
        }
        return field;
      });
    });
  }

  resetSearchForm() {
    this.refs.form.form.current.reset();
    this.onSearch(true);
  }

  onSearch(reset = false) {
    let data = this.refs.form.form.current.getData();
    console.log("onSearch formData", data, reset);
    if (reset) {
      data = {
        ...this.state.search,
      };
    } else {
      data = Object.filter(data, (val) => val);
      // console.log("data on search", data);
    }
    this.props.onSearch && this.props.onSearch(data, reset);
  }

  render() {
    return (
      <>
        {/* <form
          className="row filter-row mb-4"
          onSubmit={(...args) => this.onSearch(...args)}
        > 

          <div className="col-12 col-md-4">
            <div className="search-container">
              <select
                name="project_name"
                className="search-area"
                value={this.state.selectedStatus}
                onChange={(...args) => this.handleStatusChange(...args)}
              >
                <option value="" disabled selected>
                  Status
                </option>
                <option value="false">Enable</option>
                <option value="true">Disable</option>
              </select>
            </div>
          </div>

          <div className="col-12 col-md-8">
            <div className="search-container">
              <button className="search-btn" type="button">
                <i className="search-icon fa fa-search"></i>
              </button>
              <select
                name="project_name"
                className="search-area"
                value={this.state.selectedProject}
                onChange={(...args) => this.handleProjectChange(...args)}
              >
                <option value="" disabled selected>
                  Search
                </option>
                {this.state.projectLists.map((option, index) => (
                  <option key={index} value={option.value}>
                    {option.text}
                  </option>
                ))}
              </select>
              <button
                className="cross-btn"
                type="button"
                onClick={(...args) => this.resetSearchForm(...args)}
              >
                <i className="search-icon fa fa-close"></i>
              </button>
            </div>
          </div>
        </form> */}

        <AppForm
          className="row filter-row flex-auto justify-content-end"
          ref="form"
        >
          {this.props.hideStatus
            ? this.fields
                .filter((v) => v.name !== "status")
                .map((field, i) => (
                  <div className="col-sm-6 col-md-4" key={i}>
                    <AppInputFocus ref="inputname" {...field}></AppInputFocus>
                  </div>
                ))
            : this.fields.map((field, i) => (
                <div className="col-sm-6 col-md-4" key={i}>
                  <AppInputFocus ref="inputname" {...field}></AppInputFocus>
                </div>
              ))}
          <div className="col-sm-6 col-md-3">
            <button
              className="btn btn-success btn-block w-100"
              onClick={() => this.onSearch()}
            >
              Search
            </button>
          </div>
        </AppForm>
        <button
          type="button"
          className="btn clear_filter_btn ml-auto d-block mb-3"
          onClick={(...args) => this.resetSearchForm(...args)}
        >
          <i class="fa fa-times-circle-o me-2"></i>
          Clear Filters
        </button>
      </>
    );
  }
}

export default connect(DataCollectionSearch);
