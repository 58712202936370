export default {
    dataCollectionForm: {
        url: "/collection/form",
        method: "post"
    },
    dataCollectionList: {
        url: "/collection/form",
        method: "get"
    },
    deleteDCForm: {
        url: "/collection/form/{id}",
        method: "put"
    },
    disableDCForm: {
        url: "/collection/form/{id}",
        method: "put"
    },
    enableDCForm: {
        url: "/collection/form/{id}",
        method: "put"
    },
    updatedataCollectionForm: {
        url: "/collection/form/{id}",
        method: "put"
    },
    getDCForm: {
        url: "/collection/form/{id}",
        method: "get"
    },
    projectList: {
        url: "/project/",
        method: "get"
    },
    projectById: {
        url: "/project/{project_id}",
        method: "get"
    },
    getDCFormResponse: {
        url: "/collection/response/{id}",
        method: "get"
    },
    getDCFormResponseDetails: {
        url: "/collection/response/detail/{id}",
        method: "get"
    },
    updateDCFormResponseDetails: {
        url: "/collection/response/{id}",
        method: "put"
    },
    saveDCFormResponse: {
        url: "/collection/response",
        method: "post"
    },
    // DASHBOARD API calls
    dcdashboardData: {
        url: "/collection/dashboard",
        method: "get",
    },
    fileuploader: {
        url: "/collection/upload",
        method: "post"
    },
    filedeleter: {
        url: "/collection/upload/{file_id}",
        method: "delete"
    },
    fileDownloader: {
        url: "/collection/upload/{attachmentId}",
        method: "get"
    },
}