import React, { Component } from "react";
import LayoutWrapper from "../LayoutWrapper";
import { Link } from "react-router-dom";
import mixins from "../mixins";
import "./addresources.scss";

export class ProjectResource extends Component {
  constructor(props) {
    super(props);
    props.initialconstruct.bind(this)("ProjectResource");
    this.state = {
      columns: [],
      data: [],
      editMode: false,
      viewMode: false,
      addMode: false,
      editData: {},
      rowcount: 0,
      project: {},
      resources: [{}],
      search: {
        limit: 20,
        order_by: "updatedAt",
        order_direction: "DESC",
        page: 1,
        project_id: props?.urlparams?.project_id,
        project_fy_id: this.props?.urlparams?.project_fy_id,
      },
      activeTab: props?.urlparams?.tab || "employee",
      employeeLists: [],
      employeeIds: [],
    };
    this.formFields = [
      {
        type: "select",
        name: "project_id",
        label: "Project Name",
        defaultValue: props?.urlparams?.project_id,
        disabled: true,
        fieldclass: Object.className({
          hidden: !!props.urlparams.project_id || true,
        }),
      },
      {
        type: "select",
        name: "resource_type",
        label: "Resource Type",
        options: this._enum.PM_PROJECT_RESOURCE_TYPES,
        required: true,
        defaultValue: this.state.activeTab,
        disabled: true,
        onChange: (v) =>
          this.setState((state) => ({
            ...state,
            activeTab: v[0]?.key || props?.urlparams?.tab,
          })),
      },
      {
        type: "date",
        name: "project_on_boarding_date",
        label: "Project On-boarding Date",
        disabledDates: (date) => {
          let projectStartDate = new Date(this.state.project.project_start_date)
            .toDate()
            .date();
          let projectEndDate = new Date(this.state.project.project_end_date)
            .toDate()
            .date();
          return (
            date.getTime() < projectStartDate.getTime() ||
            date.getTime() > projectEndDate.getTime()
          );
        },
        required: true,
      },
      {
        type: "date",
        name: "project_off_boarding_date",
        label: "Project Off-boarding Date",
        // disabledDates: (date) => {
        //   let projectStartDate = new Date(this.state.project.project_start_date)
        //     .toDate()
        //     .date();
        //   let projectEndDate = new Date(this.state.project.project_end_date)
        //     .toDate()
        //     .date();
        //   return (
        //     date.getTime() < projectStartDate.getTime() ||
        //     date.getTime() > projectEndDate.getTime()
        //   );
        // },
        disabledDates: (date) => {
          let form = parseInput(this.form);
          let startDate = form.resources[0].project_on_boarding_date;
          return date.getTime() < new Date(startDate).toDate().date().getTime();
        },
        required: true,
      },
      {
        type: "select",
        name: "employee_role",
        label: "Role",
        required: true,
        options: this._enum.PM_PROJECT_ROLES,
        rule: () => this.state.activeTab !== "material",
        onChange: (...args) => this.handleRoleChange(...args),
      },
      {
        type: "textarea",
        label: "Comments (Max 250 words)",
        name: "comments",
        maxLength: "250",
        invalidmessage: "Value Should Not Exceed 250 Characters",
        controlClass: "!min-h-[150px]",
        fieldclass: "col-12",
      },
      {
        type: "text",
        name: "material_name",
        label: "Material Name",
        maxLength: "50",
        required: true,
        rule: () => this.state.activeTab == "material",
      },
      {
        type: "select",
        name: "employee_id",
        label: "Employee Name",
        options: this.state.employeeLists,
        required: true,
        rule: () =>
          this.state.activeTab !== "material" && !!this.state.employee_role,
      },
    ];
    this.tablinks = mixins.tablinks;
    this.resourcesLinks = mixins.resourcesLinks;
    this.employeetableColumns = [
      { dataIndex: "employee_role", title: "Role" },
      { dataIndex: "employee_name", title: "Employee Name" },
      {
        dataIndex: "project_on_boarding_date",
        title: "Project Onboarding Date",
        render: (text) => {
          return <>{new Date(text).dateToDDMMYYYY(new Date(text))}</>;
        },
      },
      {
        dataIndex: "project_off_boarding_date",
        title: "Project Off-boarding Date",
        render: (text) => {
          return <>{new Date(text).dateToDDMMYYYY(new Date(text))}</>;
        },
      },
    ];
    this.materialtableColumns = [
      {
        dataIndex: "material_name",
        title: "Material Name",
      },
      {
        dataIndex: "project_on_boarding_date",
        title: "Project Onboarding Date",
        render: (text) => {
          return <>{new Date(text).dateToDDMMYYYY(new Date(text))}</>;
        },
      },
      {
        dataIndex: "project_off_boarding_date",
        title: "Project Off-boarding Date",
        render: (text) => {
          return <>{new Date(text).dateToDDMMYYYY(new Date(text))}</>;
        },
      },
    ];
  }

  get formRef() {
    return this.refs?.form?.form?.current;
  }

  get form() {
    return this.formRef?.getData() || {};
  }

  get listMode() {
    return !(this.state.editMode || this.state.addMode);
  }

  get employeeOptions() {
    let { editData, editMode, viewMode } = this.state;
    let data = parseInput(this.form);
    let ids = (data?.resources || []).map((o) => o.employee_id);
    ids = ids.concat(this.state.employeeIds);
    if (editMode || viewMode) {
      ids = ids.filter((id) => id != editData.employee_id);
    }
    return this.state.employeeLists.filter((o) => !ids.includes(o.key));
  }

  resetField(name, def = "") {
    this.formRef
      .querySelector("[name='" + `appinput-${name}` + "']")
      .vnode.refs.select.clearAll();
  }

  handleRoleChange(values, value) {
    console.log({ handleRoleChange: values, value });
    let res,
      rej = () => "";
    if (value?.key) {
      this.api.apiGetEmployeeByRole(value?.key).then((response) => {
        this.setState(
          {
            employee_role: value,
            employeeLists: response.map((o) => ({
              key: o.employee_id,
              label: o.employee_name + ` ( ${o.employee_id} )`,
            })),
          },
          () => {
            let formData = this.form;
            formData = parseInput(formData);
            if (formData.resources) {
              formData.resources.map((resource, i) => {
                this.resetField(`resources.${i}.employee_id`);
              });
            }
            res();
          }
        );
      });
    }

    return new Promise((r, e) => ((res = r), (rej = e)));
  }

  addResource() {
    let { resources } = this.state;
    this.setState({
      resources: [...resources, {}],
    });
  }
  deleteResources(index) {
    confirm(`Do you realy wont to delete this item?`).promise.then(() => {
      this.setState({
        resources: [...this.state.resources.filter((o, i) => i !== index)],
      });
    });
  }

  handleSearchRoleChange(val) {
    let role = val[0]?.key;
    let searchPayload = {
      ...this.state.search,
    };
    if (role === "all") {
      delete searchPayload.employee_role;
    } else {
      searchPayload.employee_role = role;
    }
    this.setState(
      {
        search: searchPayload,
      },
      (state) => this.fetchData()
    );
  }
  handleDownloadResource() {
    let payload = {
      project_id: this.props.urlparams?.project_id,
    };
    if (this.state?.search?.employee_role)
      payload.employee_role = this.state?.search?.employee_role;

    this.props.api.apiGetExportEmployeeListByRole(payload).then(async (res) => {
      if (res) {
        let data = await res;
        let blob = new Blob([data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        let link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.setAttribute(
          "download",
          `employee_list_${new Date().toDate()}.xlsx`
        );
        link.click();
      }
    });
  }

  addRecord() {
    this.setState({
      addMode: true,
    });
  }

  submitForm(event, data, form) {
    const resolveList = () => {
      this.fetchData().then(() => {
        this.setState({
          editMode: false,
          addMode: false,
          editData: {},
          resources: [{}],
          employeeLists: [],
        });
      });
      form.reset();
    };

    let payload = parseInput(data);

    // console.log({ form: payload });
    if (this.state.editMode) {
      if (payload.resources[0].resource_type === "employee") {
        let targetEmployee = this.state.employeeLists?.filter(
          (employee) => employee.key === payload.resources[0].employee_id
        );
        let apidata = {
          employee_id: payload.resources[0]?.employee_id,
          employee_name: targetEmployee[0]?.label,
          ...Object.except(payload.resources[0], [
            "project_id",
            "resource_type",
          ]),
        };
        console.log("payload for employee edit: ", apidata);
        this.api
          .apiEditEmployeeResource(apidata, {
            resource_id: this.state.editData.resource_id,
          })
          .then(resolveList);
      } else {
        this.api
          .apiEditMaterialResource(payload.resources[0], {
            resource_id: this.state.editData.resource_id,
          })
          .then(resolveList);
      }
    } else {
      this.setState({
        editData: data,
      });

      console.log("payload: ", payload);

      if (this.state.activeTab == "employee") {
        payload.resources = payload.resources.map((resource) => ({
          ...Object.except(resource, ["resource_type"]),
          employee_name: this.state.employeeLists.find(
            (o) => o.key == resource.employee_id
          ).label,
          project_id: this.props.urlparams.project_id,
        }));
        this.api.apiAddEmployeeResource(payload.resources).then(resolveList);
      } else {
        payload.resources = payload.resources.map((resource) => ({
          ...Object.except(resource, ["resource_type"]),
          project_id: this.props.urlparams.project_id,
        }));
        this.api.apiAddMaterialResource(payload.resources).then(resolveList);
      }
    }
  }

  componentDidMount() {
    this.fetchData();
    this.getProjectDetails();
  }

  componentDidUpdate(props, state) {
    // console.log("componentDidUpdate", { props: props, state: state });
    const handleEditModeChange = () => {
      let { editData } = this.state;
      let ele = document.querySelector(
        "[name='appinput-resources.0.employee_id'"
      );
      if (ele) {
        let appselect = ele.vnode.refs.select;
        // let { values } = appselect.state;
        // values[0] = {
        //   key: editData.employee_id,
        //   label: editData.employee_name,
        // };
        // appselect.setState({ values });
      }
    };
    this.watch(
      {
        editMode: (val) => {
          if (val) {
            handleEditModeChange();
          }
        },
        viewMode: (val) => {
          if (val) {
            handleEditModeChange();
          }
        },
      },
      this.state,
      state
    );
  }

  getProjectDetails() {
    let payload = {
      project_id: this.props?.urlparams?.project_id,
    };

    this.api.apiGetProject(payload).then((data) => {
      this.setState({ project: data });
    });
  }

  getAllEmployeeIds() {
    this.api
      .apiGetEmployeeResources(
        Object.filter(
          Object.except(this.state.search, ["project_fy_id"]),
          (o, k) => !["page", "limit"].includes(k)
        )
      )
      .then(({ data }) => {
        this.setState({
          employeeIds: data.map((o) => o.employee_id),
        });
      });
  }

  fetchData() {
    return new Promise((resolve, reject) => {
      if (this.state.activeTab == "employee") {
        this.getAllEmployeeIds();
        this.api
          .apiGetEmployeeResources(
            Object.except(this.state.search, ["project_fy_id"])
          )
          .then(({ data, total }) => {
            this.setState({
              data: [...data],
              rowcount: total,
            });
            resolve();
          });
      } else {
        this.api
          .apiGetMaterialResources(this.state.search)
          .then(({ data, total }) => {
            // console.log(data);
            this.setState({
              data: [...data],
              rowcount: total,
            });
            resolve();
          });
      }
    });
  }

  editEmployeeResources(v) {
    let role = { key: v.employee_role };
    this.handleRoleChange([role], role).then(() => {
      this.setState({
        editMode: true,
        viewMode: false,
        editData: v,
      });
    });
  }
  viewEmployeeResources(v) {
    // console.log({ viewEmployeeResources: v });
    let role = { key: v.employee_role };
    this.handleRoleChange([role], role).then(() => {
      this.setState({
        editMode: true,
        viewMode: true,
        editData: v,
      });
    });
  }
  editMaterialResources(v) {
    this.setState({
      editMode: true,
      viewMode: false,
      editData: v,
    });
  }
  viewMaterialResources(v) {
    this.setState({
      editMode: true,
      viewMode: true,
      editData: v,
    });
  }
  deleteEmployeeResources(val) {
    confirm(`Do you really want to delete these records?`).promise.then(() => {
      this.api
        .apiDeleteEmployeeResource({ resource_id: val.resource_id })
        .then(() => {
          this.fetchData();
        });
    });
  }

  deleteMaterialResources(val) {
    confirm(`Do you really want to delete these records?`).promise.then(() => {
      this.api
        .apiDeleteMaterialResource({ resource_id: val.resource_id })
        .then(() => {
          this.fetchData();
        });
    });
  }

  onPageChange(pageData) {
    this.setState(
      {
        search: {
          ...this.state.search,
          page: pageData.current,
          limit: pageData.pageSize || pageData.defaultPageSize || 25,
        },
      },
      (state) => this.fetchData()
    );
  }

  getBreadCums() {
    if (this.props.urlparams.project_id) {
      return `${
        this.state.project.project_name
      } / Financial Year - ${new Date().getFinacialYear()} / Budget`;
    } else {
      return "Project / Financial Year / Task ";
    }
  }

  getBackLink() {
    if (this.props.urlparams.project_id) {
      let backLink =
        "/projectmanagement/addbudget" +
        Object.encodeuri({
          project_id: this.props.urlparams.project_id,
          project_fy_id: this.props.urlparams.project_fy_id,
          ...(this.props.urlparams.readonly
            ? { readonly: this.props.urlparams.readonly }
            : {}),
        });
      return backLink;
    }
  }

  changeTab(e, val) {
    e.preventDefault();
    e.stopPropagation();
    // console.log("tab name", val);
    this.setState({ activeTab: val });
    this.fetchData();
  }

  get tabparams() {
    return Object.except(this.props.urlparams, ["tab"]);
  }
  tabClick() {
    setTimeout(() => {
      window.location.href = window.location.href;
    });
  }

  updateFields(fields) {
    fields.find((o) => o.name === "employee_id").options = this.employeeOptions;
    return fields;
  }
  getWrapedFields(formFields, index) {
    // console.log({ formFields });
    let mappedFields = formFields.map((field) => {
      let isnewrow =
        !["employee_id", "material_name"].includes(field.name) && index > 0;
      let isprojectname = ["resource_type", "project_id"].includes(field.name);
      return {
        ...field,
        aname: field.name,
        name: `resources.${index}.${field.name}`,
        fieldclass: Object.className({
          [field.fieldclass || ""]: true,
          hidden: isnewrow || isprojectname,
        }),
        required: field.required && !(isnewrow || isprojectname),
      };
    });
    return mappedFields;
  }
  render() {
    let {
      tablinks,
      formFields,
      props,
      employeetableColumns,
      materialtableColumns,
      tabparams,
    } = this;
    let {
      columns,
      data = [],
      rowcount,
      activeTab,
      materialRowCount,
      resources,
      editData,
    } = this.state;
    formFields = this.updateFields(formFields);
    // console.log("editData: ", editData);
    formFields = formFields.map((field) => {
      field.defaultValue = field.defaultValue;
      if (![undefined].includes(editData[field.name])) {
        if (field.type == "date") {
          field.defaultValue = editData[field.name].date();
        } else {
          field.defaultValue = editData[field.name];
        }
      } else if (editData[field.name]) {
        field.defaultValue = editData[field.name];
      } else {
        field.defaultValue = "";
      }
      return field;
    });
    return (
      <LayoutWrapper
        title="Project Resource"
        back={this.getBreadCums()}
        backlink={this.getBackLink()}
      >
        <AppCircleStepper data={tablinks}></AppCircleStepper>
        <div className="row m-auto">
          <div className="col"></div>
          <div className="mb-4"></div>
        </div>

        {(props?.urlparams?.project_id && (
          <>
            {!this.listMode && (
              <div className="row m-auto">
                <AppForm
                  className="flex flex-col gap-4 bg-white p-4"
                  ref="form"
                  onSubmit={(...args) => this.submitForm(...args)}
                >
                  {resources.map((resource, index) => (
                    <div className="row" key={index}>
                      {this.getWrapedFields(formFields, index)
                        ?.filter((field) =>
                          (field?.rule || (() => true)).bind(this)()
                        )
                        .map((field, i) => (
                          <div
                            className={
                              field.fieldclass
                                ? field.fieldclass
                                : "col-12 col-md-6 col-lg-4"
                            }
                            key={"field" + i + field.name}
                          >
                            <div className="flex gap-2">
                              <AppInput
                                className="grow"
                                {...field}
                                disabled={this.state.viewMode}
                              ></AppInput>
                              {field.aname == "employee_id" && index != 0 && (
                                <div className="flex">
                                  <button
                                    type="button"
                                    className="m-auto text-red-500 border-0"
                                    onClick={() => this.deleteResources(index)}
                                  >
                                    <i className="fa fa-trash"></i>
                                  </button>
                                </div>
                              )}
                              {field.aname == "employee_id" && index == 0 && (
                                <div className="flex">
                                  <button
                                    type="button"
                                    className="m-auto text-red-500 border-0 invisible"
                                  >
                                    <i className="fa fa-trash"></i>
                                  </button>
                                </div>
                              )}
                            </div>
                          </div>
                        ))}
                    </div>
                  ))}
                  {((this.employeeOptions.length ||
                    this.state.activeTab != "employee") && (
                    <div className="row no-gutters">
                      <div>
                        <button
                          type="button"
                          className={Object.className({
                            "btn bg-transparent flex gap-2 text-primary mr-auto": true,
                            hidden: this.state.viewMode || this.state.editMode,
                          })}
                          onClick={() => this.addResource()}
                        >
                          <i className="fa fa-plus m-auto" />
                          <span className="font-bold">
                            Add {activeTab.toTitleCase()}
                          </span>
                        </button>
                      </div>
                      <div className="col"></div>
                    </div>
                  )) ||
                    ""}
                  <div className="row no-gutters">
                    <button
                      className={Object.className({
                        "btn add-btn col col-md-1 ml-auto": true,
                        hidden: this.state.viewMode,
                      })}
                    >
                      {this.state.editMode ? "Update" : "Add"}
                    </button>
                    <button
                      onClick={() =>
                        this.setState({
                          editMode: false,
                          addMode: false,
                          editData: {},
                        })
                      }
                      className={Object.className({
                        "btn btn_primary col col-md-1 grey me-2 ms-2":
                          !this.state.viewMode,
                        "btn btn_primary col col-md-1 grey me-2 ml-auto":
                          this.state.viewMode,
                      })}
                    >
                      Cancel
                    </button>
                  </div>
                </AppForm>
              </div>
            )}

            <div className="row addresources">
              <div className="col-12">
                {this.listMode && (
                  <div className="row">
                    <div className="col-12 mb-4 d-flex align-items-center">
                      <Link
                        to={
                          "/projectmanagement/addresources" +
                          "?" +
                          Object.QueryString({ ...tabparams, tab: "employee" })
                        }
                        className={Object.className({
                          "btn rounded-full border mx-1 min-w-[150px] m-clip tab_class": true,
                          "btn-success text-white": !["employee"].includes(
                            activeTab
                          ),
                          "btn-current": activeTab == "employee",
                        })}
                        onClick={() => this.tabClick()}
                      >
                        Employee
                      </Link>
                      <Link
                        to={
                          "/projectmanagement/addresources" +
                          "?" +
                          Object.QueryString({ ...tabparams, tab: "material" })
                        }
                        className={Object.className({
                          "btn rounded-full border mx-1 min-w-[150px] m-clip tab_class": true,
                          "btn-success text-white": !["material"].includes(
                            activeTab
                          ),
                          "btn-current": activeTab == "material",
                        })}
                        onClick={() => this.tabClick()}
                      >
                        Material
                      </Link>

                      <div className="d-flex align-items-center ms-auto mw-150-input">
                        <AppInputFocus
                          ref="inputname"
                          name="employee_role"
                          label="Role"
                          type="select"
                          options={[
                            {
                              key: "all",
                              label: "All",
                            },
                            ..._enum.EMP_ROLES,
                          ]}
                          className="select-focus w-100"
                          onChange={(...args) =>
                            this.handleSearchRoleChange(...args)
                          }
                        ></AppInputFocus>
                        <button
                          type="button"
                          className="btn add-btn mx-2"
                          onClick={() => this.handleDownloadResource()}
                        >
                          <i class="fa fa-download me-2"></i>
                          Download
                        </button>
                      </div>

                      {this.listMode && (
                        <button
                          className={Object.className({
                            "btn add-btn": true,
                            hidden:
                              this.props.urlparams.readonly == 1 ? true : false,
                          })}
                          onClick={() => this.addRecord()}
                        >
                          Add
                        </button>
                      )}
                    </div>
                  </div>
                )}
                {!!data.length && this.listMode && activeTab === "employee" && (
                  <AppTable
                    data={data}
                    columns={employeetableColumns}
                    onNext={() => this.onNext()}
                    onPrev={() => this.onPrev()}
                    onChange={(...arg) => this.onPageChange(...arg)}
                    total={rowcount}
                    reorder={true}
                    deleteAction={(v) => this.deleteEmployeeResources(v)}
                    deletable={
                      this.props.urlparams.readonly == 1 ? false : true
                    }
                    editable={this.props.urlparams.readonly == 1 ? false : true}
                    editAction={(v) => this.editEmployeeResources(v)}
                    viewAction={(v) => this.viewEmployeeResources(v)}
                    pageSize={10}
                    targetType="tap"
                  ></AppTable>
                )}

                {!!data.length && this.listMode && activeTab === "material" && (
                  <AppTable
                    data={data}
                    columns={materialtableColumns}
                    onNext={() => this.onNext()}
                    onPrev={() => this.onPrev()}
                    onChange={(...arg) => this.onPageChange(...arg)}
                    total={rowcount}
                    reorder={true}
                    deleteAction={(v) => this.deleteMaterialResources(v)}
                    deletable={
                      this.props.urlparams.readonly == 1 ? false : true
                    }
                    editable={this.props.urlparams.readonly == 1 ? false : true}
                    editAction={(v) => this.editMaterialResources(v)}
                    viewAction={(v) => this.viewMaterialResources(v)}
                    pageSize={10}
                    targetType="tap"
                  ></AppTable>
                )}
                {data.length == 0 && <div className="empty_layout"></div>}
              </div>
            </div>

            {this.listMode && (
              <div className="row">
                <div className="col-12 mt-4">
                  <Link to={"/projectmanagement"} className="btn add-btn">
                    Submit
                  </Link>
                </div>
              </div>
            )}
          </>
        )) ||
          ""}
      </LayoutWrapper>
    );
  }
}

export default connect(ProjectResource);
