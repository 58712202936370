const getapi = function () {
    let api = window.api;
    api = getpathkey(api);
    api = {
        ...api,
        ...getpathkey(api)
    };
    return api;
};
export const commonService = {
    api: getapi,
    apiSuccess(resolve = ((v) => (v)), id = 0, responce = {}) {
        return resolve(responce.data);
    },
    applogout() {
        app.props.setstore("auth", false);
        app.props.setstore("user", false);
        localStorage.clear();
    },
    apiFailure(reject, id, error) {
        let app = window?.app;
        if (error?.response?.status === 400) {
            let message = (
                error.response?.data?.issues?.message ||
                error.response?.data?.issues?.error ||
                error.response?.data?.issues?.data.error ||
                error.response?.data?.message ||
                error.response?.data?.msg
            )
            alert(message);
        } else if (error?.response?.status == 502 || error?.response?.status == 404) {
            let message = (
                error.response?.data?.issues?.message
            )
            alert(message);
        }
        else if (error?.response?.status == 401) {
            let refreshTokenAttemp = localStorage.getItem("refreshTokenAttemp");
            refreshTokenAttemp = refreshTokenAttemp || 0;
            if (refreshTokenAttemp <= 1) {
                mixins.debounce(() => {
                    localStorage.setItem("refreshTokenAttemp", +refreshTokenAttemp + 1);
                    console.log({ refreshTokenAttemp });
                    app?.props?.api
                        .apiRefreshToken()
                        .then(() => {
                            window.location.reload();
                        }
                        )
                        .catch(() => {
                            app.props.api.applogout()
                        });
                });
            } else {
                // alert("Token is invalid or expired");
                app.props.api.applogout()
            }
        }
        console.log({ error }, error?.response?.status);
        let globalLoader = document.getElementById("globalLoader");
        setTimeout(() => {
            globalLoader?.classList.add("d-none")
        }, 700);
        return reject(error);
    },
    endpoint2url(endpoint) {
        let api = getapi();
        return endpoint.startsWith("http") ? endpoint : api.api_base + endpoint;
    },
    request(endobj = "", post = {}, header = {}) {
        let endpoint = endobj.url;
        let objmethod = endobj.method;
        let data = post;
        let axios = window.axios;
        let api = getapi();
        let globalLoader = document.getElementById("globalLoader");

        let inHeaders = {
            headers: {
                authorization: "Bearer " + window.store.getState().root.auth,
                ...header
            },
        };
        let bundel = {
            payload: {},
            urltransform: (data = app.mixin.params(), apiendobj = endobj) => {
                let func = apiendobj.url;
                if (func instanceof Function) {
                    endpoint = func(data);
                } else {
                    endpoint = Object.entries(data).reduce((url, [key, val]) => {
                        return url.split(`{${key}}`).join(val);
                    }, apiendobj.url);
                }
                bundel.url = endpoint.startsWith("http") ? endpoint : api.api_base + endpoint;
                return bundel;
            },
            multipart: () => {
                inHeaders.headers = {
                    ...(inHeaders.headers || {}),
                    "Content-Type": "multipart/form-data"
                };
                return bundel;
            },
            responsetype: (type) => {
                inHeaders.responseType = type;
                return bundel;
            },
            config: (reqconfig) => {
                let temp = reqconfig instanceof Function ? reqconfig(inHeaders) : reqconfig;
                inHeaders = {
                    ...inHeaders,
                    ...temp
                }
                return bundel;
            },
            headers: (reqconfig) => {
                let temp = reqconfig instanceof Function ? reqconfig(inHeaders.headers) : reqconfig;
                inHeaders.headers = {
                    ...temp
                }
                return bundel;
            },
            transform(callback = ((v) => (v))) {
                data = callback(post);
                return bundel;
            },
            exec: (message = false, method = "get") => {
                globalLoader?.classList.remove("d-none");
                bundel.results = new Promise((resolve, reject) => {
                    let app = window?.app;
                    let id = null;
                    if (message) {
                        id = Math.random().toString().slice(2);
                        app.mixin.pushRequest({ message, id, type: "loader" });
                    }
                    method = objmethod || method || (data instanceof FormData ? "post" : "get");
                    let url = this.endpoint2url(endpoint);
                    let isGet = method == "get";
                    let isDelete = method == "delete";
                    url = isGet ? url + Object.encodeuri(data) : url;
                    let requestarr = isGet ? ({ url, inHeaders }) : ({ url, data, inHeaders });
                    requestarr = isDelete ? ({
                        url, inHeaders: {
                            ...inHeaders,
                            data
                        }
                    }) : requestarr;
                    bundel.payload.messageId = id;
                    axios[method](...Object.values(requestarr))
                        .then((...arg) => this.apiSuccess(resolve, id, ...arg))
                        .catch((...arg) => this.apiFailure(reject, id, ...arg));
                });
                return bundel;
            },
            log() {
                console.log({ endobj, post, data, Headers: inHeaders, responce: bundel.results });
                return bundel;
            },
            mapresults(callback = ((v) => (v))) {
                const execpromise = bundel.results;
                const newpromise = new Promise((resolve, reject) => {
                    execpromise.then(async (data) => {
                        let resultdata = callback(data);
                        if (resultdata instanceof Promise) {
                            resultdata = await resultdata;
                        }
                        resolve(resultdata)
                    }).catch(reject);
                });
                bundel.results = newpromise;
                return bundel;
            },
            get() {
                let app = window?.app;
                const execpromise = bundel.results;
                const newpromise = new Promise((resolve, reject) => {
                    execpromise.then(async (data) => {
                        resolve(data)
                        setTimeout(() => {
                            globalLoader?.classList.add("d-none")
                        }, 700);
                    }).catch((...args) => {
                        console.log("bundel error", ...args);
                        reject(...args);
                    }).then(() => {
                        this.payload.messageId && app.mixin.popRequest(this.payload.messageId);
                    });
                });
                return newpromise;
            }
        };
        return bundel;
    },
    mokeapi(callback = (() => ('')), message = "Getting data please wait...", delay = 1000) {
        return new Promise((resolve, reject) => {
            let app = window?.app;
            let id = Math.random().toString().slice(2);
            message && app.mixin.pushRequest({ message, id });
            setTimeout(() => {
                resolve(callback());
                message && app.mixin.popRequest(id);
            }, delay);
        });
    }
}

export default commonService;