/**
 * Signin Firebase
 */

import React, { Component } from "react";
import { Modal } from "react-bootstrap-v5";
import { Link } from "react-router-dom";
import { LayoutWrapper } from "./LayoutWrapper";
import "./datacollection.scss";
import * as XLSX from "xlsx";
import DataCollectionSearch from "./DataCollectionSearch";

export class DataCollectionForm extends Component {
  constructor(props) {
    super(props);
    props.initialconstruct.bind(this)("DataCollectionForm");
    this.state = {
      data: [],
      showModal: false,
      deleteRecord: null,
      rowcount: 0,
      search: {
        limit: 20,
        orderBy: "updatedOn",
        orderDirection: "DESC",
        page: 1,
      },
      showdisableAction: true,
      projectsList: [],
      projectID: "",
      changePageNo: false,
      columns: [
        {
          dataIndex: "formName",
          title: "Form Name",
          sorter: (a, b) => a.formName.length - b.formName.length,
          // render: (text, record) => {
          //   return (
          //     <span
          //       className="text_truncate short cursor-pointer"
          //       title={record?.formName}
          //       onClick={() => alert(record?.formName)}
          //     >
          //       <b>{record?.formName}</b>
          //     </span>
          //   );
          // },
        },
        {
          dataIndex: "role",
          title: "Role",
          sorter: (a, b) => a.role.length - b.role.length,
          render: (text, record) => {
            return <span>{this.capitalizeWords(text)}</span>;
          },
        },
        {
          display: false,
          dataIndex: "language",
          title: "Language",
          sorter: (a, b) => a.language.length - b.language.length,
        },
        {
          dataIndex: "projectName",
          title: "Project Name",
          sorter: (a, b) => a.projectName.length - b.projectName.length,
          render: (text, record) => {
            return (
              <span
                className="text_truncate cursor-pointer"
                title={record?.projectName}
                // onClick={() => alert(record?.projectName)}
              >
                {this.capitalizeWords(record?.projectName)}
              </span>
            );
          },
        },
        {
          display: false,
          dataIndex: "projectManagerName",
          title: "Project Manager Name",
          sorter: (a, b) =>
            a.projectManagerName.length - b.projectManagerName.length,
          render: (text, record) => {
            return (
              <span
                className="text_truncate cursor-pointer"
                title={record?.projectManagerName}
                onClick={() => alert(record?.projectManagerName)}
              >
                {record?.projectManagerName}
              </span>
            );
          },
        },
        {
          dataIndex: "createdOn",
          title: "Created On",
          render: (text) => {
            let dt = new Date(text);
            return <>{dt.dateToDDMMYYYY(dt)}</>;
          },
        },
        {
          dataIndex: "updatedOn",
          title: "Updated On",
          render: (text) => {
            let dt = new Date(text);
            return <>{dt.dateToDDMMYYYY(dt)}</>;
          },
        },
        {
          dataIndex: "disable",
          title: "Status",
          render: (text) => {
            return (
              <span className={text == true ? "text-danger" : "text-success"}>
                {text == true ? "Disable" : "Enable"}
              </span>
            );
          },
        },
        {
          dataIndex: "numberOfResponse",
          title: "Response",
          display: false,
          render: (text, record) => {
            return (
              <div className="flex gap-2">
                {text == 0 ? (
                  "No Responses"
                ) : (
                  <Link
                    to={`/datacollection/datacollectionresponse?id=${record?.id}&preview=1`}
                    className="link_btn"
                  >
                    {text}
                  </Link>
                )}
              </div>
            );
          },
        },
      ],
    };
    this.actionList = [
      {
        id: "disable",
        title: "Disable",
        icon: "fa fa-toggle-off",
        callback: (v) => this.disableDCForm(v),
        isVisible: (record) => {
          if (!record.disable) return true;
        },
      },
      {
        id: "enable",
        title: "Enable",
        icon: "fa fa-toggle-on",
        callback: (v) => this.enableDCForm(v),
        isVisible: (record) => {
          if (record.disable) return true;
        },
      },
      {
        id: "getresponse",
        title: "Get Response",
        icon: "fa fa-arrow-circle-right",
        callback: (v) => this.getDcResponses(v),
      },
    ];
  }

  get isAdmin() {
    return this.props.store.user?.employee_role === "admin";
  }

  get isManager() {
    return this.props.store.user?.employee_role === "manager";
  }

  deleDCForm(form) {
    this.setState({ deleteRecord: form, showModal: true });
  }

  confirmDelete = () => {
    const { deleteRecord } = this.state;
    if (deleteRecord) {
      this.api.apiDeleteDCForm(deleteRecord).then(() => {
        this.fetchList();
        this.setState({ showModal: false, deleteRecord: null });
      });
    }
  };

  cancelDelete = () => {
    this.setState({ showModal: false, deleteRecord: null });
  };

  editDCForm(form) {
    this.navigate(`/datacollection/datacollectioninfo?id=${form.id}`);
  }

  viewDCForm(form) {
    this.navigate(
      `/datacollection/datacollectioninfo?id=${form.id}&readonly=1`
    );
  }

  exportDCForm(record) {
    console.log({ exportDCForm: record });
    confirm("Are you sure want to export this Data ?").promise.then(() => {
      let link = record?.exportFileDownload || "";
      let exportDate = record?.exportFileCreationDTM || "";
      let dt = new Date(exportDate.date()).dateToDDMMYYYY(exportDate?.date());
      prompt(
        "",
        {},
        <div className="export-details-box">
          <p>
            Last Updated : <span className="">{dt}</span>
          </p>
          <a href={link} className="link">
            {link}
          </a>
        </div>,
        "Copy Link",
        "No"
      ).promise.then(() => {
        navigator.clipboard.writeText(link);
        this.mixin.alert("Copied to clipboard");
      });
    });
  }

  // fallbackExport() {
  //   this.mixin.alert("No responses found");
  // }

  disableDCForm(form) {
    if (!form.disable) {
      let payload = {
        id: form.id,
      };
      confirm(`Do you really want to disable these records?`).promise.then(
        () => {
          this.api.apiDisableDCForm(payload).then(() => {
            this.navigate("/datacollection");
            success("Form Disabled");
            this.fetchList();
          });
        }
      );
    } else {
      alert("Form Already Disabled");
    }
  }

  enableDCForm(form) {
    console.log({ form: form });
    if (form.disable === true) {
      let payload = {
        id: form.id,
      };
      this.api.apiEnableDCForm(payload).then(() => {
        this.navigate("/datacollection");
        success("Form Enabled");
        this.fetchList();
      });
    } else {
      alert("Form Already Enabled");
    }
  }

  getDcResponses(form) {
    if (form) {
      this.navigate(
        `/datacollection/datacollectionresponse?id=${form?.id}&preview=1`
      );
    }
  }

  componentDidMount() {
    this.fetchList();
    this.fetchProjectsList();
    console.log("mounted");
  }

  fetchList(params, reset) {
    let payload = {};

    if (reset) {
      const { employee_id, ...newState } = this.state.search;
      this.setState({
        search: newState,
      });

      payload = {
        ...Object.except(this.state.search, ["disable", "projectId"]),
        ...params,
      };
    } else {
      payload = {
        ...this.state.search,
        ...params,
      };
    }

    console.log({ payload });

    this.api.apiDataCollectionFormList(payload).then(({ data, total }) => {
      this.setState({
        data: data,
        rowcount: total,
        changePageNo: false,
      });
    });
  }

  fetchProjectsList() {
    this.props.api.apiGetProjectsList().then((response) => {
      this.setState({
        projectsList: response.data,
      });
    });
  }

  capitalizeWords(value) {
    return value
      .split(", ")
      .map((word) =>
        word
          .split("-")
          .map(
            (part) => part.charAt(0).toUpperCase() + part.slice(1).toLowerCase()
          )
          .join("-")
      )
      .join(", ");
  }

  resetSearchForm() {
    this.setState(
      {
        projectID: "",
        search: {
          ...this.state.search,
        },
      },
      () => this.fetchList()
    );
  }

  onPageChange(pageData) {
    this.setState(
      {
        search: {
          ...this.state.search,
          page: pageData.current,
          limit: pageData.pageSize || pageData.defaultPageSize || 25,
        },
      },
      (state) => this.fetchList()
    );
  }

  copyDCForm(form) {
    let url = window.location.origin + `/public/dcfresponse?id=${form.id}`;
    window.open(url);

    // navigator.clipboard.writeText(
    //   window.location.origin + `/public/dcfresponse?id=${form.id}`
    // );
    // this.mixin.alert("Copied to clipboard");
  }

  onSearch(data, reset) {
    console.log("onSearch on list", data, reset);
    if (Object.keys(data).length === 0) {
      this.setState(
        {
          search: {
            ...data,
            ...this.state.search,
          },
        },
        () => this.fetchList()
      );
    } else {
      this.setState(
        {
          search: {
            ...data,
            ...this.state.search,
          },
        },
        () => this.fetchList(data, reset)
      );
    }
  }

  getDownloadData() {
    let { rowcount, search } = this.state;
    let pageCount = Math.ceil(rowcount / search.limit);
    console.log(pageCount);
    let promises = [];
    promises.length = pageCount;
    promises.fill(0);
    console.log(promises);

    promises = promises.map((v, i) =>
      this.api.apiDataCollectionFormList({
        ...this.state.search,
        page: i + 1,
      })
    );
    console.log(promises);
    Promise.all(promises).then((results) => {
      let totalrows = results.reduce((col, item) => col.concat(item.data), []);
      // let { data } = this.processData(totalrows);
      totalrows = totalrows.map((item) => {
        return {
          ...item,
          createdOn: new Date(item.createdOn).dateToDDMMYYYY(
            new Date(item.createdOn)
          ),
          updatedOn: new Date(item.updatedOn).dateToDDMMYYYY(
            new Date(item.updatedOn)
          ),
        };
      });
      console.log({ totalrows });
      this.handleDownload(totalrows);
    });
  }

  handleDownload = (totaldata = []) => {
    const { data: pagedata, columns } = this.state;
    let data = totaldata.length ? totaldata : pagedata;
    const tabledata = data.reduce((arr, row) => {
      let mrow = Object.only(row, [
        "formName",
        "role",
        "language",
        "projectName",
        "projectManagerName",
        "createdOn",
        "updatedOn",
      ]);
      arr.push(mrow);
      return arr;
    }, []);
    let groups = {};
    let addeddata = tabledata.reduce((arr, trow, index) => {
      let row = data[index];
      let isRowHasArray = Object.values(trow).reduce(
        (flag, value) => flag || value instanceof Array,
        false
      );
      let arrRemovedRow = Object.filter(trow, (v) => !(v instanceof Array));
      arrRemovedRow = Object.filter(
        arrRemovedRow,
        (v, k) => !["projectId", "formId"].includes(k)
      );
      arr.push(arrRemovedRow);
      if (isRowHasArray) {
        let formId = row._id;
        let formName = row.formName;
        let arrayValues = Object.filter(trow, (v) => v instanceof Array);
        Object.entries(arrayValues).map(([key, values], j) => {
          groups[formId + "-" + j] = groups[formId + "-" + j] || [];
          values.map((value) => {
            groups[formId + "-" + j].push({
              id: formId,
              formName,
              subcategory: key,
              ...value,
            });
          });
        });
      }
      return arr;
    }, []);
    let groupsarr = Object.entries(groups).reduce((arr, [key, values]) => {
      arr = arr.concat(values);
      return arr;
    }, []);
    // console.log("data", data, { tabledata, addeddata, groups, groupsarr });
    // console.log("columns", columns);
    let mainHeaders = Object.keys(addeddata[0] || {});

    let mainData = addeddata.map((v) => mainHeaders.map((k) => v[k]));
    let groupHeaders = Object.keys(groupsarr[0] || {});
    let groupData = groupsarr.map((v) => groupHeaders.map((k) => v[k]));
    let concatedHeader = [...mainHeaders, ...groupHeaders.slice(2)];
    let concatedData = mainData.reduce((c, r) => {
      c.push(r);
      let grouprows = groupData.filter((o) => o[0] == r[0]);
      if (grouprows.length) {
        grouprows.map((gr) => {
          c.push([...r, ...gr.slice(2)]);
        });
      }
      return c;
    }, []);
    const exportData = [concatedHeader, ...concatedData];
    console.log("mainheaders", {
      mainHeaders,
      addeddata,
      tabledata,
      exportData,
    });
    //return;

    // Create a new workbook and worksheet
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.aoa_to_sheet(exportData);

    // Add the worksheet to the workbook
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

    // Generate a unique filename for the Excel sheet
    const filename = `datacollection_${new Date().getTime()}.xlsx`;

    // Export the workbook as an Excel file

    XLSX.writeFile(workbook, filename);
  };

  render() {
    const links = mixins.menulinks;
    let { columns, data, rowcount } = this.state;
    let { actionList, isAdmin, isManager } = this;

    columns.forEach((column) => {
      if (
        column.dataIndex === "createdOn" ||
        column.dataIndex === "updatedOn"
      ) {
        column.render = (text, record) => (
          <span>{new Date(text).dateToDDMMYYYY(new Date(text))}</span>
        );
      }
    });

    return (
      <LayoutWrapper
        title="Data Collection"
        back="Configuration"
        // back="Dashboard"
      >
        <Modal
          show={this.state.showModal}
          centered
          size="md"
          onHide={this.cancelDelete}
          className="custom-modal"
        >
          <Modal.Header closeButton>
            {/* <Modal.Title>Confirmation</Modal.Title> */}
          </Modal.Header>
          <Modal.Body>
            <h3 className="w-75 text-center m-auto mb-4">
              Are you sure want to delete this form ?
            </h3>
          </Modal.Body>
          <Modal.Footer>
            <button className="orangeButton" onClick={this.confirmDelete}>
              Yes
            </button>
            <button className="greyButton" onClick={this.cancelDelete}>
              No
            </button>
          </Modal.Footer>
        </Modal>

        <div className="row d-flex justify-content-between">
          <div className="col-12 col-md-3 mb-4 d-flex align-items-center justify-content-start">
            <Link
              to="/datacollection/datacollectioninfo"
              className={Object.className({
                "btn add-btn mb-4": true,
                hidden: !isAdmin,
              })}
            >
              <i className="fa fa-plus" /> Add Form
            </Link>
          </div>
          <div className="col-12 col-md-9 col-xl-8">
            <DataCollectionSearch
              inactive={this.props.inactive}
              onSearch={(...arg) => this.onSearch(...arg)}
              fetchList={() => this.fetchList()}
              resetSearchForm={() => this.resetSearchForm()}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <AppTable
              data={data}
              columns={columns}
              onNext={() => this.onNext()}
              onPrev={() => this.onPrev()}
              onChange={(...arg) => this.onPageChange(...arg)}
              total={rowcount}
              reorder={true}
              preview={true}
              {...(isAdmin
                ? {
                    editAction: (v) => this.editDCForm(v),
                    deleteAction: (v) => this.deleDCForm(v),
                    copyLinkAction: (v) => this.copyDCForm(v),
                    viewAction: (v) => this.viewDCForm(v),
                    exportAction: (v) => this.exportDCForm(v),
                    actionList,
                    copyable: true,
                    exportable: true,
                  }
                : isManager
                ? {
                    deletable: false,
                    editable: false,
                    viewable: false,
                    actionList: actionList?.filter(
                      (v) => v.id === "getresponse"
                    ),
                  }
                : {
                    deletable: false,
                    editable: false,
                    copyable: false,
                    viewable: false,
                    exportable: false,
                  })}
              targetType="tap"
              showeditAction={this.state.showeditAction}
            ></AppTable>
          </div>
        </div>
      </LayoutWrapper>
    );
  }
}

export default connect(DataCollectionForm);
